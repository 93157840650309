import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class PackageItem extends Model {
  @Field()
  serviceId?: string;
  @Field()
  quantity?: number;
  @Field()
  discountPercentage?: number;

  constructor(obj?: object) {
    super(obj);
  }
}
