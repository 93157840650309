import { Component, OnInit } from '@angular/core';
import { AccessPagePermits } from '../../sub-modules/ic-core/decorators/access-page-permits.decorator';

export interface HomeConfiguration {
  id: string;
  title: string;
  icon: string;
  link: string;
  badge?: string | number;
}

@Component({
  selector: 'ic-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
@AccessPagePermits(['home.page.access'])
export class HomeComponent implements OnInit {
  shortCutIcons: HomeConfiguration[] = require('../../../assets/home-applications.json');

  ngOnInit(): void {
    // This stage is required in AccessPagePermits => await Promise.resolve(ngOnInit.bind(this)());
  }
}
