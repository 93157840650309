// reference https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_image_compare

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'ic-image-comparison',
  templateUrl: './image-comparison.component.html',
  styleUrls: ['./image-comparison.component.scss'],
})
export class ImageComparisonComponent implements OnInit, AfterViewInit {
  @Input() sources: Array<string>;
  @Input() width: string;
  @Input() height: string;

  @ViewChild('container', { static: false }) containerRef: ElementRef;

  // @ViewChild('firstImageContainer', {static: true}) firstImageContainerRef: ElementRef;
  @ViewChild('slider', { static: false }) sliderRef: ElementRef;
  @ViewChild('secondImageContainer', { static: false }) secondImageContainerRef: ElementRef;

  // private firstImageContainer;
  private slider;
  private secondImageContainer;

  private offsetWidth;
  private offsetHeight;

  private sliding = false;

  constructor() {}

  ngOnInit(): void {
    this.width = this.width || '300px';
    this.height = this.height || '200px';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.slider = this.sliderRef.nativeElement;
      this.secondImageContainer = this.secondImageContainerRef.nativeElement;

      this.offsetWidth = this.containerRef.nativeElement.offsetWidth;
      this.offsetHeight = this.containerRef.nativeElement.offsetHeight;

      this.slider.style.top = this.offsetHeight / 2 - this.slider.offsetHeight / 2 + 'px';
      this.slider.style.left = this.offsetWidth / 2 - this.slider.offsetWidth / 2 + 'px';
    }, 250);

    // setTimeout(initComparisons);
  }

  private slide(x) {
    /*resize the image:*/
    this.secondImageContainer.style.width = x + 'px';
    /*position the slider:*/
    this.slider.style.left =
      this.secondImageContainer.offsetWidth - this.slider.offsetWidth / 2 + 'px';
  }

  private getCursorPos(event) {
    // event = event || window.event;

    /*get the x positions of the image:*/
    const rect = this.secondImageContainer.getBoundingClientRect();

    /*calculate the cursor's x coordinate, relative to the image:*/
    let x = event.pageX - rect.left;

    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    return x;
  }

  startSlide(event) {
    /*prevent any other actions that may occur when moving over the image:*/
    event.preventDefault();
    this.sliding = true;
  }

  slideMove(event) {
    let pos;
    /*if the slider is no longer clicked, exit this function:*/
    if (!this.sliding) {
      return;
    }
    /*get the cursor's x position:*/
    pos = this.getCursorPos(event);
    /*prevent the slider from being positioned outside the image:*/
    if (pos < 0) {
      pos = 0;
    }
    if (pos > this.offsetWidth) {
      pos = this.offsetWidth;
    }
    /*execute a function that will resize the overlay image according to the cursor:*/
    this.slide(pos);
  }

  endSlide(event) {
    this.sliding = false;
  }
}
