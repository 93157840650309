import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeConfiguration } from '../../../pages/home/home.component';
import { Autowired, Permission, Role, Selector } from '../../ic-core';
import { ModelAttributes } from '../interface/model-attributes';
import { PermissionString } from '../interface/permission-string';
import { RoleRepository } from '../repositories/role.repository';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private authenticatedApps: HomeConfiguration[];
  private modelAttributes: ModelAttributes;
  @Autowired()
  protected roleRepository: RoleRepository;

  async createRole(role: Role): Promise<Role> {
    try {
      role = await this.roleRepository.create(role);
    } catch (err) {
      throw err;
    }
    return role;
  }

  async updateRole(dataChanged: Role): Promise<void> {
    await this.roleRepository.update(dataChanged);
  }

  async deleteRole(id): Promise<void> {
    await this.roleRepository.delete(id);
  }

  searchRoles(query: any): Observable<Role[]> {
    return this.roleRepository.search(query);
  }

  async getRole(roleId: string): Promise<Role> {
    return await this.roleRepository.get(roleId).toPromise();
  }

  async getQuery(query: any, roleId: string): Promise<Role> {
    return await this.roleRepository.getQuery(query, roleId).toPromise();
  }

  generateEmptyRole(): Role {
    return new Role({
      roleName: '',
      permissions: [],
    });
  }

  getPermissionStringsOfModule(module: string, permissions: Permission[]): PermissionString[] {
    const allPermissionStrings = Array.from(
      new Set(permissions.map((permission) => permission.permissionString))
    );
    const permissionStringsByModule = allPermissionStrings.filter((permissionString) =>
      [module, '*'].includes(permissionString.split('.')[0])
    );
    return this.getPermissionStringObjects(permissionStringsByModule);
  }

  getPermissionStringObjects(permissionStrings: string[]): PermissionString[] {
    const regexp = /^(\b(\w*)\b|\*)\.(\b(\w*)\b|\*)\.(\b(\w*)\b|\*)$/;
    const permissionStringObjects: PermissionString[] = [];
    permissionStrings.forEach((permission) => {
      if (permission.match(regexp)) {
        const permissionObj = permission.split('.');
        permissionStringObjects.push({
          modelName: permissionObj[0],
          attributeName: permissionObj[1],
          operationName: permissionObj[2],
        });
      }
    });
    return permissionStringObjects;
  }

  async getPermissionsByUserRole(roles: Role[]): Promise<Permission[]> {
    let permissions: Permission[] = [];
    for (const role of roles) {
      const selector: Selector = {
        $embeddedFields: ['permission'],
      };
      const userRole: Role = await this.getQuery(selector, role.id);

      if (userRole) {
        permissions = [...permissions, ...userRole.permissions];
      }
    }
    return permissions;
  }

  setAuthenticatedApps(apps: HomeConfiguration[]): void {
    this.authenticatedApps = [];
    this.authenticatedApps = apps;
  }

  getModelAttributes(): ModelAttributes {
    return this.modelAttributes;
  }

  setModelAttributesFromPermissionStringObjects(
    modelName: string,
    permissionStringObjects: PermissionString[]
  ): void {
    const viewAttributes = permissionStringObjects
      .filter((p) => ['*', 'view'].includes(p.operationName))
      .map((p) => p.attributeName);
    const addAttributes = permissionStringObjects
      .filter((p) => ['*', 'add'].includes(p.operationName))
      .map((p) => p.attributeName);
    const editAttributes = permissionStringObjects
      .filter((p) => ['*', 'edit'].includes(p.operationName))
      .map((p) => p.attributeName);
    const deleteAttributes = permissionStringObjects
      .filter((p) => ['*', 'delete'].includes(p.operationName))
      .map((p) => p.attributeName);
    this.modelAttributes = {
      modelName,
      viewAttributes,
      addAttributes,
      editAttributes,
      deleteAttributes,
    };
  }
}
