import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class EmbeddedServiceItem extends Model {
  constructor(obj?: object) {
    super(obj);
  }

  @Field()
  serviceCode?: string;

  @Field()
  serviceName?: string;
}
