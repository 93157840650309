import { IcInjectorService } from '..';
import { AuthService } from '../../auth';

export function AccessPagePermits(permits: Array<string | object> | string | object) {
  let validatePermits = {};
  if (!permits) {
    return;
  }

  if (!Array.isArray(permits)) {
    permits = [permits];
  }
  (permits as Array<string | object>).forEach((permit) => {
    switch (typeof permit) {
      case 'string':
        validatePermits[permit] = () => true;
        break;
      case 'object':
        validatePermits = Object.assign(validatePermits, permit);
    }
  });

  const validate = async (context) => {
    const authService: AuthService = IcInjectorService.getInstance(
      AuthService as any
    ) as AuthService;
    // authService.permissions
    const results = await Promise.all(
      Object.entries(validatePermits).map(([permit, validatePermit]) => {
        if (authService.permissions.includes('*.*.*') || authService.permissions.includes(permit)) {
          // @ts-ignored
          return Promise.resolve(validatePermit.call(context));
        } else {
          return false;
        }
      })
    );
    if (!results.includes(true)) {
      window.location.href = '/home';
    }
  };

  // https://www.logicbig.com/tutorials/misc/typescript/class-decorators.html

  // tslint:disable-next-line:ban-types
  return (constructor: Function) => {
    const ngOnInit = constructor.prototype.ngOnInit;

    constructor.prototype.ngOnInit = initialization;

    async function initialization() {
      await Promise.resolve(ngOnInit.bind(this)());
      await validate(this);
    }
  };
}
