import { Entity, Field } from '../decorators/decorators';
import { Address } from './address';
import { Model } from './model';

@Entity()
export class Asset extends Model {
  @Field()
  assetName?: string;

  @Field()
  category?: string;

  @Field()
  description?: string;

  @Field()
  amount?: number;

  @Field()
  comment?: string;

  @Field()
  address: Address;

  constructor(obj?: object) {
    super(obj);
  }
}
