import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';
import { Permission } from './permission';

@Entity()
export class Role extends Model {
  @Field()
  roleName?: string;

  @Field({ itemType: Permission })
  permissions?: Array<Permission>;

  constructor(obj?: object) {
    super(obj);
  }
}
