import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { AmplifyService } from 'aws-amplify-angular';
import { combineLatest, from, Observable, Subject } from 'rxjs';

import { EmployeeApi } from '../../accounts';
import { RoleRepository } from '../../authorization/repositories/role.repository';
import { Autowired, DialogService, Employee } from '../../ic-core';
import { IdleService } from '../../ic-core/services/idle.service';

export interface AuthData {
  result: boolean;
  user?: any;
  error?: any;
  email?: any;
}

export const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements Resolve<void> {
  loggedInEmployee: Employee;
  permissions: Array<string> = [];
  timeoutTitle: string;
  timeoutMessage: string;
  secondTimeoutCountDown$: Subject<string> = new Subject();

  private readonly SECOND_TIMEOUT_IN_MINUTES = 1;
  private signedInUser = null;
  private signInActions: Array<({ user: User }) => void> = [];
  private signOutActions: Array<({ user: User }) => void> = [];

  @Autowired()
  private employeeApi: EmployeeApi;

  @Autowired()
  protected roleRepository: RoleRepository;

  constructor(
    private readonly amplifyService: AmplifyService,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly translateService: TranslateService,
    private readonly idleService: IdleService
  ) {
    this.listenSignInOut();
    this.getTimeoutDialogTranslation();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    return this.awaitLogged();
  }

  async awaitLogged(): Promise<void> {
    return new Promise<void>((resolve) => {
      if (this.loggedInEmployee) {
        this.autoResetTimerAndRenewToken(this.signedInUser);
        resolve();
      } else if (sessionStorage.getItem('accessToken')) {
        this.amplifyService.authStateChange$.subscribe(async (authState) => {
          if (authState.state === 'signedIn') {
            if (!!authState.user) {
              this.signedInUser = authState.user;
              await this.loadLoggedInEmployee();
              await this.loadPermissions();
            }
            resolve();
          }
        });
      } else {
        resolve();
      }
    });
  }

  private async loadLoggedInEmployee(): Promise<void> {
    if (!this.loggedInEmployee) {
      if (sessionStorage.getItem('loggedInEmployee')) {
        this.loggedInEmployee = new Employee(
          JSON.parse(sessionStorage.getItem('loggedInEmployee'))
        );
      } else {
        if (this.signedInUser && this.signedInUser.username) {
          this.loggedInEmployee = await this.findEmployee(this.signedInUser.username);
          if (this.loggedInEmployee) {
            sessionStorage.setItem('loggedInEmployee', JSON.stringify(this.loggedInEmployee));
          }
        }
      }
    }
    const timeoutInMinutes: number = this.getAccessTokenTimeoutInMinutes(this.signedInUser);
    if (timeoutInMinutes !== 0) {
      this.initTimer(timeoutInMinutes, this.SECOND_TIMEOUT_IN_MINUTES);
    } else {
      await this.handleTokenExpiration();
    }
  }

  private async handleTokenExpiration(): Promise<void> {
    if (!this.isSameAccessToken()) {
      await this.refreshAccessToken();
    }
  }

  private isSameAccessToken(): boolean {
    return (
      this.signedInUser.signInUserSession.accessToken.jwtToken ===
      sessionStorage.getItem('accessToken')
    );
  }

  private async loadPermissions(): Promise<void> {
    let loggedInEmployee = this.loggedInEmployee;
    if (loggedInEmployee) {
      loggedInEmployee = await this.findEmployee(this.signedInUser.username);
    }
    loggedInEmployee.roles = await Promise.all(
      this.loggedInEmployee.roles.map((role) => {
        return this.roleRepository.get(role.id).toPromise();
      })
    );
    loggedInEmployee.roles = loggedInEmployee.roles.filter((role) => !!role);
    loggedInEmployee.roles.map((role) => {
      if (Array.isArray(role.permissions)) {
        role.permissions.forEach((permission) => {
          if (permission.permissionString) {
            this.permissions.push(permission.permissionString);
          }
        });
      }
    });
    this.loggedInEmployee = loggedInEmployee;
  }

  public async getLoggedInEmployee(): Promise<Employee> {
    return this.loggedInEmployee;
  }

  private executeSignInActions(user): void {
    this.signInActions.forEach((action) => action({ user }));
  }

  private executeSignOutActions(user): void {
    this.signOutActions.forEach((action) => action({ user }));
  }

  public addSignInListener(action: ({ user: User }) => void): void {
    this.signInActions.push(action);
  }

  private listenSignInOut(): void {
    this.amplifyService.authStateChange$.subscribe(async (authState) => {
      if (authState.state === 'signedIn') {
        if (!!authState.user) {
          this.signedInUser = authState.user;
          await this.loadLoggedInEmployee();
          await this.loadPermissions();
          this.executeSignInActions({ user: this.loggedInEmployee });
        } else {
          if (!!this.loggedInEmployee) {
            const loggedOutEmployee = this.loggedInEmployee;
            this.signedInUser = null;
            this.loggedInEmployee = null;
            this.executeSignOutActions({ user: loggedOutEmployee });
          }
          sessionStorage.removeItem('accessToken');
          sessionStorage.removeItem('loggedInEmployee');
        }
      } else if (authState.state === 'signedOut') {
        const loggedOutEmployee = this.loggedInEmployee;
        this.signedInUser = null;
        this.loggedInEmployee = null;
        this.permissions = [];
        this.executeSignOutActions({ user: loggedOutEmployee });
        sessionStorage.removeItem('accessToken');
        sessionStorage.removeItem('loggedInEmployee');
      }
    });
  }

  isSignedIn(): boolean {
    return !!this.loggedInEmployee;
  }

  async signIn(username, password): Promise<AuthData> {
    try {
      const user = await Auth.signIn(username, password);
      if (user.signInUserSession && user.signInUserSession.idToken) {
        sessionStorage.setItem('accessToken', user.signInUserSession.idToken.jwtToken);
      }
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        // console.log(`Cognito MFA Challenge`);
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return { result: true, user };
      } else if (user.challengeName === 'MFA_SETUP') {
        // This happens when the MFA method is TOTP
        // The user needs to setup the TOTP before using it
        // More info please check the Enabling MFA part
      } else {
        return { result: true, user };
      }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
      } else if (err.code === 'PasswordResetRequiredException') {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (err.code === 'NotAuthorizedException') {
        // The error happens when the incorrect password is provided
      } else if (err.code === 'UserNotFoundException') {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
      } else {
        // Some other errors
      }
      this.loggedInEmployee = undefined;
      return { result: false, error: err.message };
    }
  }

  signOut(): void {
    Auth.signOut({})
      .then(() => {
        this.dialogService.closeDialog();
        // stop all timer and end the session
        IdleService.runTimer = false;
        IdleService.runSecondTimer = false;
        this.loggedInEmployee = undefined;
        this.router.navigate(['/auth/sign-in']);
      })
      .catch((err) => console.log(err));
  }

  async forgetPassword(username): Promise<AuthData> {
    try {
      const result = await Auth.forgotPassword(username);
      return { result: true, email: result.CodeDeliveryDetails.Destination };
    } catch (err) {
      return { result: false, error: err.message };
    }
  }

  async resetPassword(username, code, newPassword): Promise<AuthData> {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      return { result: true };
    } catch (err) {
      return { result: false, error: err.message };
    }
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<AuthData> {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      return { result: true };
    } catch (err) {
      if (err.code === 'NotAuthorizedException') {
        err.message = 'Old Password is incorrect';
      }
      return { result: false, error: err.message };
    }
  }

  async signUp(username: string, email: string, password: string): Promise<AuthData> {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          phone_number: '',
        },
        validationData: [],
      });
      return { result: true };
    } catch (err) {
      return { result: false, error: err.message };
    }
  }

  async activeAccount(username: string, verificationCode: string): Promise<AuthData> {
    try {
      // username = await this.findEmployeeName(username);
      await Auth.confirmSignUp(username, verificationCode, {
        // Optional. Force user confirmation irrespective of existing alias. By default set to True.
        forceAliasCreation: true,
      });
      return { result: true };
    } catch (err) {
      return { result: false, error: err.message };
    }
  }

  async resendActiveAccountCode(username: any): Promise<AuthData> {
    try {
      username = await this.findEmployeeName(username);
      await Auth.resendSignUp(username);
      return { result: true };
    } catch (err) {
      return { result: false, error: err.message };
    }
  }

  async completeNewPassword(newPassword: any): Promise<AuthData> {
    try {
      this.signedInUser = await Auth.completeNewPassword(
        this[NEW_PASSWORD_REQUIRED],
        newPassword,
        {}
      );
      this[NEW_PASSWORD_REQUIRED] = undefined;
      return { result: true };
    } catch (err) {
      return { result: false, error: err.message };
    }
  }

  async findEmployeeName(emailOrPhone): Promise<Employee> {
    const employee = await this.findEmployee(emailOrPhone);
    if (employee) {
      this.loggedInEmployee = employee;
      if (employee.contact && employee.contact.email) {
        emailOrPhone = employee.contact.email;
      }
    }
    return emailOrPhone;
  }

  async autoResetTimerAndRenewToken(user: CognitoUser): Promise<void> {
    if (user) {
      // stop second timer and initiate first timer again
      IdleService.runSecondTimer = false;
      this.idleService.initilizeSessionTimeout();
      this.getAccessTokenTimeoutInMinutes(user);
      await this.handleTokenExpiration();
    }
  }

  private getAccessTokenTimeoutInMinutes(user: CognitoUser): number {
    const timeout: number =
      user.getSignInUserSession().getAccessToken().getExpiration() * 1000 - new Date().valueOf();
    const timeoutInMinutes: number = new Date(timeout).getMinutes();
    console.log(`Timeout in ${timeoutInMinutes} minutes`);
    return timeoutInMinutes;
  }

  private openTimeoutDialog(): void {
    from(
      this.dialogService.openTimeout(
        this.timeoutTitle,
        this.timeoutMessage,
        this.secondTimeoutCountDown$.asObservable()
      )
    ).subscribe(async (value) => {
      if (value) {
        // stop second timer and initiate first timer again
        IdleService.runSecondTimer = false;
        this.idleService.initilizeSessionTimeout();
        await this.refreshAccessToken();
        sessionStorage.removeItem('loadLoggedInEmployee');
        await this.loadLoggedInEmployee();
        location.reload();
      } else {
        this.signOut();
      }
    });
  }

  private async findEmployee(emailOrPhone): Promise<Employee> {
    const employees: Array<Employee> = await this.employeeApi.search({
      $or: [{ email: emailOrPhone }, { primaryPhone: emailOrPhone }],
    });
    if (Array.isArray(employees) && employees.length) {
      return employees[0];
    } else {
      return undefined;
    }
  }

  private async refreshAccessToken(): Promise<void> {
    /* Refresh aws token */
    await Auth.currentSession()
      .then((result) => {
        if (this.signedInUser) {
          this.signedInUser.signInUserSession.accessToken.jwtToken = result
            .getIdToken()
            .getJwtToken();
          sessionStorage.setItem(
            'accessToken',
            this.signedInUser.signInUserSession.accessToken.jwtToken
          );
          this.getAccessTokenTimeoutInMinutes(this.signedInUser);
        }
      })
      .catch((err) => {
        console.log(err);
        this.loggedInEmployee = undefined;
        sessionStorage.clear();
        this.router.navigate(['/auth/sign-in']);
      });
  }

  private getTimeoutDialogTranslation(): void {
    const timeoutTitle$: Observable<string> = from(
      this.translateService.get('Authorization.AccessToken.Timeout.Title')
    );
    const timeoutMessage$: Observable<string> = from(
      this.translateService.get('Authorization.AccessToken.Timeout.Message')
    );
    combineLatest(timeoutTitle$, timeoutMessage$).subscribe(([title, message]) => {
      this.timeoutTitle = title;
      this.timeoutMessage = message;
    });
  }

  private initTimer(firstTimerValue: number, secondTimerValue: number): void {
    // Timer value initialization
    this.idleService.USER_IDLE_TIMER_VALUE_IN_MIN = firstTimerValue;
    this.idleService.FINAL_LEVEL_TIMER_VALUE_IN_MIN = secondTimerValue;
    // end

    // Watcher on timer
    this.idleService.initilizeSessionTimeout();
    this.idleService.userIdlenessChecker.subscribe((status: string) => {
      this.initiateFirstTimer(status);
    });

    this.idleService.secondLevelUserIdleChecker.subscribe((status: string) => {
      this.secondTimeoutCountDown$.next(status);
      this.initiateSecondTimer(status);
    });
  }

  private initiateFirstTimer(status: string): void {
    switch (status) {
      case 'INITIATE_TIMER':
        break;

      case 'RESET_TIMER':
        break;

      case 'STOPPED_TIMER':
        this.openTimeoutDialog();
        break;
    }
  }

  private initiateSecondTimer(status: string): void {
    switch (status) {
      case 'INITIATE_SECOND_TIMER':
        break;

      case 'SECOND_TIMER_STARTED':
        break;

      case 'SECOND_TIMER_STOPPED':
        this.signOut();
        break;

      default:
        break;
    }
  }
}
