import { Injectable } from '@angular/core';
import { AbstractDao, Treatment } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class TreatmentDao extends AbstractDao<Treatment> {
  constructor() {
    super(Treatment);
  }

  protected postInit() {}
}
