import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';
import { SaleTarget } from './sale-target';

@Entity()
export class SaleTargetSet extends Model {
  @Field()
  effectiveDate: Date;
  @Field()
  expirationDate: Date;
  @Field({ itemType: SaleTarget })
  saleTargets?: Array<SaleTarget>;
  constructor(obj?: object) {
    super(obj);
  }
}
