import { Injectable } from '@angular/core';
import { Autowired, IcConfig, InitializerProvider, User } from '..';
import { AuthService } from '../../auth';

@Injectable({
  providedIn: 'root',
})
export class SocketEventService {
  private socket: WebSocket;
  private events: Array<(data) => void> = [];

  @Autowired()
  private initializerProvider: InitializerProvider;
  @Autowired()
  private authService: AuthService;
  constructor() {
    // this.initSocket();
  }

  public initSocket() {
    const icConfig: IcConfig = this.initializerProvider.CONFIG;
    const socketURL = icConfig.api.socket.url;
    this.socket = new WebSocket(socketURL);
    this.socket.onopen = async () => {
      this.registerUserInfo(await this.authService.getLoggedInEmployee());
      setTimeout(() => {
        this.socket.onmessage = (message) => {
          this.events.forEach((event) => {
            event(message);
          });
        };
      }, 1000);
    };
  }

  private registerUserInfo(user: User) {
    const message = {
      action: 'onregister',
      userId: user.id,
    };
    this.socket.send(JSON.stringify(message));
  }

  public sendMessage(message) {
    const msg = {
      action: 'onmessage',
      message,
    };
    this.socket.send(JSON.stringify(msg));
  }

  public addMessageListener(event: (message) => void) {
    this.events.push(event);
  }

  public removeMessageListener(event: (message) => void) {
    this.events = this.events.filter((eventLoop) => eventLoop !== event);
  }
}
