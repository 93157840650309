import { EventEmitter } from '@angular/core';
import PouchDB from 'pouchdb';
import PouchFind from 'pouchdb-find';
import { Model } from '../models/model';
import { UtilityService } from '../services/utility.service';

PouchDB.plugin(PouchFind);

export class AbstractDao<T extends Model> {
  private readonly databaseName: string;
  private readonly modelClass: new (obj: object) => T;
  private db: PouchDB.Database;
  private changeListener: EventEmitter<any> = new EventEmitter<any>();

  constructor(clazz: new (obj: object) => T) {
    this.modelClass = clazz;
    this.databaseName = clazz.name.replace(/[A-Z]/g, (match, offset) => {
      return (offset ? '_' : '') + match.toLowerCase();
    });

    this.db = new PouchDB(this.databaseName);
    this.getInfo()
      .then((details) => {
        if (details.doc_count === 0 && details.update_seq === 0) {
          this.postInit();
        }
      })
      .catch((err) => {
        console.log('error: ' + err);
        return;
      });
  }

  // create default data
  protected postInit(): void {}

  getChangeListener(): EventEmitter<any> {
    return this.changeListener;
  }

  async getInfo(): Promise<PouchDB.Core.DatabaseInfo> {
    return await this.db.info();
  }

  async get(id: string): Promise<T> {
    try {
      const doc = await this.db.get(id);
      return new this.modelClass(doc);
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.trace(err.message);
      return null;
    }
  }

  async getAll(): Promise<T[]> {
    let result;
    try {
      result = await this.db.allDocs({
        include_docs: true,
        attachments: true,
      });
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.trace(err.message);
    }
    return UtilityService.parseDataToModel(result.docs, this.modelClass);
  }

  async search(query: object): Promise<T[]> {
    let result;
    try {
      result = await this.db.find({ selector: query });
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.trace(err.message);
    }
    return !!result ? result.docs.map((item) => new this.modelClass(item)) : [];
  }

  async create(doc: T): Promise<T> {
    let result = null;
    if (!doc.id) {
      try {
        result = await this.db.post(doc); // Create a new document and let PouchDB auto-generate an _id for it.
        /*
                Example Response:
                {
                  "ok" : true,
                  "id" : "8A2C3761-FFD5-4770-9B8C-38C33CED300A",
                  "rev" : "1-d3a8e0e5aa7c8fff0c376dac2d8a4007"
                }
                */
        doc.id = result.id;
      } catch (err) {
        // tslint:disable-next-line:no-console
        console.trace(err.message);
        throw err;
      }
    } else {
      await this.replace(doc); // Create a new doc with an _id of 'mydoc'
    }

    return doc;
  }

  async replace(obj: T): Promise<T> {
    try {
      if (!obj._id) {
        obj._id = obj.id;
      }
      await this.db.put(obj); // Create a new doc with an _id of 'mydoc'
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.trace(err.message);
      throw err;
    }
    return obj;
  }

  async update(obj: T): Promise<T> {
    let doc = await this.get(obj.id);
    if (!doc) {
      return this.create(obj);
    } else {
      doc = Object.assign(doc, obj);
      return this.replace(doc);
      // doc = new this.modelClass(JSON.parse(JSON.stringify(doc)));
    }
  }

  // tslint:disable-next-line:variable-name
  async delete(_id): Promise<{} | PouchDB.Core.Response> {
    try {
      const doc = await this.db.get(_id);
      return await this.db.remove(doc._id, doc._rev);
    } catch (err) {
      if (err.reason === 'missing') {
        // there is no _id in dao
        return {};
      } else {
        // tslint:disable-next-line:no-console
        console.trace(err.message);
        throw err;
      }
    }
  }
}
