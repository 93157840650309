import { Entity, Field } from '../decorators/decorators';
import { UtilityService } from '../services/utility.service';

function setObject(obj: object, instance: any) {
  const properties: Array<{
    name: string;
    type: new (...args: any[]) => void;
    mapping: string;
    itemType: new (...args: any[]) => void;
  }> =
    // @ts-ignore
    UtilityService.findDeclaredProperties(instance.constructor);
  if (!!obj) {
    properties.forEach(({ name, type, itemType }) => {
      if (name === '_id') {
        // @ts-ignore
        instance.id = obj.id || obj._id;
      } else {
        const value = obj[name];
        if (value !== undefined && value !== null) {
          if (UtilityService.isEntity(type)) {
            instance[name] = new type(value);
          } else if (/*type.name === 'Array' &&*/ !!itemType && UtilityService.isEntity(itemType)) {
            instance[name] = (value as Array<any>).map((item) => new itemType(item));
          } else if (value.constructor.name === type.name) {
            instance[name] = value;
          } else {
            switch (type.name) {
              case 'Date':
                instance[name] = new Date(value);
                break;
              case 'String':
                instance[name] = value + '';
                break;
              case 'Number':
                instance[name] = +value;
                break;
              case 'Boolean':
                instance[name] =
                  !!value &&
                  !['no', 'false', 'off', 'disable'].includes(value.toString().toLowerCase());
            }
          }
        }
      }
    });
  }
}

function parseModelToData(instance: any) {
  if (!UtilityService.isEntity(instance.constructor)) {
    return instance;
  }
  const obj = {};
  const properties: Array<{
    name: string;
    type: new (...args: any[]) => void;
    mapping: string;
    itemType: new (...args: any[]) => void;
  }> =
    // @ts-ignore
    UtilityService.findDeclaredProperties(instance.constructor);
  if (!!instance) {
    properties.forEach(({ name, type, mapping, itemType }) => {
      let value = instance[name];
      if (value !== undefined && value !== null && value !== '') {
        if (Array.isArray(value)) {
          value = parseArrayModelToData(value as Array<any>);
        } else if (!!value && value instanceof Model) {
          value = parseModelToData(value);
        }
        UtilityService.setDeeplyProperty(obj, mapping, value);
      }
    });
  }
  return obj;
}

function parseArrayModelToData(instances: Array<any>): object[] {
  return instances.map((instance) => parseModelToData(instance));
}

@Entity()
export class Model {
  @Field({ mapping: 'id' })
  // tslint:disable-next-line:variable-name
  _id?: string;
  @Field()
  // tslint:disable-next-line:variable-name
  _rev?: string;

  @Field()
  createdDate?: Date;
  @Field()
  modifiedDate?: Date;

  constructor(obj: object) {
    if (!!obj) {
      try {
        setObject(obj, this);
      } catch (err) {
        // tslint:disable-next-line:no-console
        console.trace(err.message);
        throw err;
      }
    }
  }

  set id(id) {
    this._id = id;
  }

  get id() {
    return this._id;
  }

  public toRequestData(): object {
    return parseModelToData(this);
  }

  public fromRequestData(data: object): void {
    UtilityService.setDataToInstance(data, this);
  }
}

// tslint:disable-next-line:no-namespace
export namespace Status {
  export type TypeEnum =
    | 'done'
    | 'pending'
    | 'draft'
    | 'pendingApproval'
    | 'approved'
    | 'failed'
    | 'rejected';
  export const TypeEnum = {
    Done: 'done' as TypeEnum,
    Pending: 'pending' as TypeEnum,
    Draft: 'draft' as TypeEnum,
    PendingApproval: 'pendingApproval' as TypeEnum,
    Approved: 'approved' as TypeEnum,
    Failed: 'failed' as TypeEnum,
    Rejected: 'rejected' as TypeEnum,
  };
}
