import { Injectable } from '@angular/core';
import { Autowired, CustomerProfile } from '../../ic-core';
import { AbstractDeepRepository } from '../../ic-core/repository/abstract.deep.repository';
import { CustomerProfileApi } from '../api/customer-profile.api';
import { CustomerProfileDao } from '../dao/customer-profile.dao';

@Injectable({
  providedIn: 'root',
})
export class CustomerProfileRepository extends AbstractDeepRepository<CustomerProfile> {
  @Autowired()
  protected dao: CustomerProfileDao;
  @Autowired()
  protected api: CustomerProfileApi;

  constructor() {
    super(CustomerProfile);
  }

  async create(t: CustomerProfile, deepPath: string): Promise<CustomerProfile> {
    // skip create server side
    await this.dao.create(t);
    return t;
  }
}
