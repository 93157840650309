import { AfterViewInit, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PageLoaderService } from './sub-modules/ic-core';

@Component({
  selector: 'ic-root',
  templateUrl: './app.container.html',
  styleUrls: ['./app.container.scss'],
})
// tslint:disable-next-line:component-class-suffix
export class AppContainer implements AfterViewInit {
  constructor(
    private translate: TranslateService,
    private router: Router,
    private pageLoaderService: PageLoaderService
  ) {
    translate.setDefaultLang('vi');
  }

  ngAfterViewInit(): void {
    this.pageLoaderService.hidePageLoader();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
  }

  isAuthenticationUrl(): boolean {
    return this.router.url.startsWith('/auth/');
  }
}
