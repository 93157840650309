import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmDialog } from '../..';

@Component({
  selector: 'ic-take-photo-dialog',
  templateUrl: './take-photo.dialog.html',
  styleUrls: ['./take-photo.dialog.scss'],
})
// tslint:disable-next-line:component-class-suffix
export class TakePhotoDialog implements OnInit {
  // @Output() taken = new EventEmitter<{ file: File | Blob, dataUrl: string, base64String: string | ArrayBuffer }>();

  constructor(public dialogRef: MatDialogRef<TakePhotoDialog>) {}

  ngOnInit(): void {}

  takenPhoto(event) {
    // this.taken.emit(event);
    this.dialogRef.close(event);
  }
}
