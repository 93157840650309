import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class Address extends Model {
  @Field()
  street?: string;
  @Field()
  ward?: string;
  @Field()
  district?: string;
  @Field()
  city?: string;
  constructor(obj?: object) {
    super(obj);
  }
}
