import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
// import * as jQuery from 'jquery';
declare var jQuery: any;

// https://weareoutman.github.io/clockpicker/
@Component({
  selector: 'ic-clock-picker',
  templateUrl: './clock-picker.component.html',
  styleUrls: ['./clock-picker.component.scss'],
})
export class ClockPickerComponent implements OnInit, AfterViewInit {
  public val: string;
  private clockPickerControl;
  // tslint:disable-next-line:variable-name
  private _clock: { hours: number; minutes: number };

  @Input('clock')
  set clock(clock: { hours: number; minutes: number }) {
    if (this._clock !== clock) {
      this._clock = clock;
      this.val = this._clock ? this._clock.hours + ':' + this._clock.minutes : '';
      if (this.clockPicker && this.clockPicker.nativeElement) {
        this.initClock();
      }
    }
  }

  @Output() clockChange = new EventEmitter<{ hours: number; minutes: number }>();
  // tslint:disable-next-line:no-output-native no-output-on-prefix no-output-rename
  @Output() change = new EventEmitter<{ hours: number; minutes: number }>();

  @ViewChild('clockPicker', { static: false }) clockPicker: ElementRef;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.initClock();
  }

  initClock(): void {
    this.clockPickerControl = jQuery(this.clockPicker.nativeElement)
      .clockpicker({
        placement: 'bottom',
        align: 'left',
        autoclose: true,
      })
      .change(() => {
        const val = this.clockPicker.nativeElement.value;
        if (val && val.split(':').length >= 2) {
          this._clock = { hours: val.split(':')[0], minutes: val.split(':')[1] };
          this.clockChange.emit(this._clock);
          this.change.emit(this._clock);
        }
      });
  }

  showInput(event) {
    event.stopPropagation();
    this.clockPickerControl.clockpicker('show');
  }
}
