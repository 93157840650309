import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Autowired } from '../decorators/decorators';
import { Model } from '../models/model';
import { Selector } from '../models/selector';
import { InitializerProvider } from '../providers/initializer.provider';
import { UtilityService } from '../services/utility.service';

export interface SelectorProperty {
  field?: any;
  value?: any;
  type?: any;
  condition?: any;
}

const AUTHORIZATION = 'Authorization';
export class AbstractApi<T extends Model> {
  // tslint:disable-next-line:variable-name
  protected _url: string;
  // https://medium.com/better-programming/a-generic-http-service-approach-for-angular-applications-a7bd8ff6a068
  protected contextPath: string;
  protected deepPath: string;
  protected uri: string;
  protected modelClass: new (obj: object) => T;

  public get url() {
    if (!!this._url) {
      return this._url;
    } else {
      return this.deepPath
        ? `${this.contextPath}/${this.deepPath}/${this.uri}`
        : `${this.contextPath}/${this.uri}`;
    }
  }

  get accessToken() {
    return sessionStorage.getItem('accessToken');
  }

  @Autowired()
  protected httpClient: HttpClient;

  @Autowired()
  protected initializerProvider: InitializerProvider;

  constructor(clazz: new (obj: object) => T) {
    this.modelClass = clazz;
    this._url = this.initializerProvider.CONFIG.api.url[
      clazz.name[0].toLowerCase() + clazz.name.substring(1)
    ];
    if (!this._url) {
      this.contextPath = this.initializerProvider.CONFIG.api.contextPath;
      this.uri = this.initializerProvider.CONFIG.api.uri[
        clazz.name[0].toLowerCase() + clazz.name.substring(1)
      ];
    }
  }

  // tslint:disable-next-line:no-shadowed-variable
  async request<T>(
    method: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS',
    url: string,
    options?: {
      body?: any;
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      observe?: 'body';
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Promise<T> {
    options = options || {};
    options.headers = options.headers || {};
    let accessToken = this.accessToken;
    while (!this.accessToken) {
      await UtilityService.delay(100);
      accessToken = this.accessToken;
    }

    options.headers[`Authorization`] = accessToken;
    let t: T;
    try {
      t = await this.httpClient.request<T>(method, url, options).toPromise();
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.trace(err.message);
      throw err;
    }
    return t;
  }

  async manualRequest(
    method: 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'GET' | 'HEAD' | 'JSONP' | 'OPTIONS',
    url: string,
    body?: object
  ): Promise<T> {
    let accessToken = this.accessToken;
    while (!this.accessToken) {
      await UtilityService.delay(100);
      accessToken = this.accessToken;
    }
    return new Promise<T>((resolve, reject) => {
      const xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
      xmlhttp.onloadend = () => {
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/StatusR
        if (xmlhttp.status > 0 && xmlhttp.status < 400) {
          resolve(JSON.parse(xmlhttp.responseText));
        } else {
          reject(new Error('Error ' + xmlhttp.status + ' - ' + xmlhttp.responseText));
        }
      };
      xmlhttp.open(method, url, true);
      xmlhttp.setRequestHeader(AUTHORIZATION, accessToken);
      xmlhttp.send(!!body ? JSON.stringify(body) : undefined);
    });
  }

  async get(id, deepPath?: string): Promise<T> {
    this.deepPath = deepPath;
    try {
      const t: T = await this.request<T>('GET', `${this.url}/${id}`);
      const model = new this.modelClass({});
      model.fromRequestData(t);
      return model;
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.trace(err.message);
      return null;
    }
  }

  async getQuery(query: object, id, deepPath?: string): Promise<T> {
    const httpParams: string = this.buildHttpParams(query, deepPath);
    try {
      const t: T = await this.request<T>('GET', `${this.url}/${id}` + '?' + httpParams);
      const model = new this.modelClass({});
      model.fromRequestData(t);
      return model;
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.trace(err.message);
      return null;
    }
  }

  async search(query: object, deepPath?: string): Promise<T[]> {
    const httpParams: string = this.buildHttpParams(query, deepPath);
    const t: any = await this.request<T[]>('GET', this.url + '?' + httpParams);
    if (!!t && Array.isArray(t.records)) {
      return t.records.map((record) => {
        const model = new this.modelClass({});
        model.fromRequestData(record);
        return model;
      });
    } else if (!!t && Array.isArray(t)) {
      return t.map((record) => {
        const model = new this.modelClass({});
        model.fromRequestData(record);
        return model;
      });
    } else {
      return [];
    }
  }

  private buildHttpParams(query: object, deepPath?: string): string {
    this.deepPath = deepPath;
    let httpParams = '';
    const selectors = this.buildSelectors(query);
    Object.entries(selectors).forEach(([key, value]) => {
      httpParams = httpParams + '&' + key + '=' + value;
    });
    return (httpParams = httpParams.substr(1));
  }

  private buildSelectors(query: Selector) {
    let result: any = {};
    let selectorProperty: SelectorProperty = {};

    let selectors: Selector[];

    if (query.$options) {
      if (query.$options.topId) {
        result['searchCriteria[lastEvaluatedKey]'] = query.$options.topId;
      }

      if (query.$options.sortBy) {
        result['sortCriteria[byAttribute]'] = query.$options.sortBy;
        result['sortCriteria[order]'] = query.$options.sortOrder || 'ASC';
        result['searchCriteria[currPage]'] = query.$options.pageIndex || 1;
        result['sortCriteria[type]'] = query.$options.sortType || 'String';
      }

      result['searchCriteria[pageSize]'] = query.$options.limit;
      query.$options = undefined;
    }

    if (query.$embeddedFields) {
      result.embeddedProperties = query.$embeddedFields.join(',');
      query.$embeddedFields = undefined;
    }

    if (query.$filterByEmployeeId) {
      result.filterByEmployeeId = query.$filterByEmployeeId;
      query.$filterByEmployeeId = undefined;
    }

    if (query.$or) {
      selectors = query.$or;
      selectors.forEach((selector, index) => {
        [result, selectorProperty] = this.buildSelectorTemplate(
          result,
          selectorProperty,
          selector,
          0,
          index
        );
      });
    } else {
      if (query.$and) {
        selectors = query.$and;
      } else {
        selectors = Object.entries(query).map(([k, v]) => {
          const re = {};
          re[k] = v;
          return re;
        });
      }
      selectors.forEach((selector, index) => {
        if (!!selector.$or) {
          const selectorOrs = selector.$or;
          selectorOrs.forEach((selectorOr, indexOr) => {
            [result, selectorProperty] = this.buildSelectorTemplate(
              result,
              selectorProperty,
              selectorOr,
              index,
              indexOr
            );
          });
        }

        [result, selectorProperty] = this.buildSelectorTemplate(
          result,
          selectorProperty,
          selector,
          index,
          0,
          true
        );
      });
    }
    return result;
  }

  private buildSelectorTemplate(
    result,
    selectorProperty: SelectorProperty,
    selector: Selector,
    firstParam: number,
    secondParam: number,
    useCollectionSelectorKeys?: boolean
  ): [any, SelectorProperty] {
    const collectionSelectorKeys = ['$in', '$nin'];
    const singleSelectorKeys: string[] = [
      '$lt',
      '$gt',
      '$lte',
      '$gte',
      '$eq',
      '$like',
      '$ne',
      '$exists',
    ];
    const key = Object.keys(selector)[0];
    selectorProperty.field = key;
    if (!!selector[key]) {
      if (['String', 'Number', 'Date'].includes(selector[key].constructor.name)) {
        selectorProperty.value = selector[key];
        selectorProperty.type = selector[key].constructor.name;
        selectorProperty.type = selectorProperty.type === 'Date' ? 'String' : selectorProperty.type;
        if (selectorProperty.type === 'Number') {
          selectorProperty.type = Number.isInteger(selectorProperty.value) ? 'Integer' : 'Decimal';
        }
        selectorProperty.condition = 'eq';
        if (selectorProperty.value === 0 || !!selectorProperty.value) {
          this.buildResultProperty(result, selectorProperty, firstParam, secondParam);
        }
      } else if (singleSelectorKeys.includes(Object.keys(selector[key])[0])) {
        // field = key;
        [selectorProperty.condition, selectorProperty.value] = Object.entries(selector[key])[0];
        selectorProperty.type = selectorProperty.value.constructor.name;
        selectorProperty.condition = selectorProperty.condition.substr(1);
        selectorProperty.type = selectorProperty.type === 'Date' ? 'String' : selectorProperty.type;
        if (selectorProperty.type === 'Number') {
          selectorProperty.type = Number.isInteger(selectorProperty.value) ? 'Integer' : 'Decimal';
        }
        if (selectorProperty.value === 0 || !!selectorProperty.value) {
          this.buildResultProperty(result, selectorProperty, firstParam, secondParam);
        }
      } else {
        const isIncludedKey: boolean = useCollectionSelectorKeys
          ? collectionSelectorKeys.includes(Object.keys(selector[key])[0])
          : singleSelectorKeys.includes(Object.keys(selector[key])[0]);
        if (isIncludedKey) {
          // field = key;
          [selectorProperty.condition, selectorProperty.value] = Object.entries(selector[key])[0];
          selectorProperty.type = selectorProperty.value[0].constructor.name;
          selectorProperty.condition = selectorProperty.condition.substr(1);
          selectorProperty.type =
            selectorProperty.type === 'Date' ? 'String' : selectorProperty.type;
          selectorProperty.value = selectorProperty.value.join(',');
          if (!!selectorProperty.value) {
            this.buildResultProperty(result, selectorProperty, firstParam, secondParam);
          }
        }
      }
    }
    return [result, selectorProperty];
  }

  private buildResultProperty(
    result,
    selectorProperty: SelectorProperty,
    firstParam: number,
    secondParam: number
  ): void {
    result[`searchCriteria[filter_groups][${firstParam}][filters][${secondParam}][field]`] =
      selectorProperty.field;
    result[`searchCriteria[filter_groups][${firstParam}][filters][${secondParam}][value]`] =
      selectorProperty.value;
    result[
      `searchCriteria[filter_groups][${firstParam}][filters][${secondParam}][condition_type]`
    ] = selectorProperty.condition;
    result[`searchCriteria[filter_groups][${firstParam}][filters][${secondParam}][type]`] =
      selectorProperty.type;
  }

  async getAll() {}

  async create(obj: T, deepPath?: string): Promise<T> {
    this.deepPath = deepPath;
    if (!obj.id) {
      obj.id = await this.generatorId();
    }

    const t: T = await this.manualRequest(
      'POST',
      this.url,
      new this.modelClass(obj).toRequestData()
    );
    obj = Object.assign(obj, t);
    return obj;
  }

  private async generatorId() {
    try {
      const idGeneratorUri = this.initializerProvider.CONFIG.api.uri.idGenerator;
      const url = `${
        this.contextPath
      }/${idGeneratorUri}?resourceType=${this.modelClass.name.replace(/^./, (match) =>
        match.toLocaleLowerCase()
      )}`;
      const t: any = await this.httpClient
        .request('GET', url, { headers: { AUTHORIZATION: this.accessToken } })
        .toPromise();
      return t.id;
    } catch (err) {
      return undefined;
    }
  }

  async replace(t: T, deepPath?: string) {
    this.deepPath = deepPath;
    await this.manualRequest('PUT', `${this.url}/${t.id}`, new this.modelClass(t).toRequestData());
  }

  async update(t: T, deepPath?: string) {
    if (window.location.pathname.endsWith('/new')) {
      return await this.create(t, deepPath);
    } else {
      this.deepPath = deepPath;
      await this.manualRequest(
        'PATCH',
        `${this.url}/${t.id}`,
        new this.modelClass(t).toRequestData()
      );
    }
  }

  async updateMany(query: object, obj: object) {
    let httpParams = new HttpParams();
    Object.keys(query).forEach((key) => {
      httpParams = httpParams.append(key, query[key]);
    });

    return await this.request<T>('PATCH', this.url, {
      body: obj,
      params: httpParams,
      headers: { AUTHORIZATION: this.accessToken },
    });
  }

  async delete(id, deepPath?: string) {
    this.deepPath = deepPath;
    return await this.request<T>('DELETE', `${this.url}/${id}`, {
      headers: { AUTHORIZATION: this.accessToken },
    });
  }
}
