import { Entity, Field } from '../decorators/decorators';
import { Contact } from './contact';
import { Model } from './model';

@Entity()
export class User extends Model {
  @Field()
  fullName?: string;
  @Field()
  birthday?: Date;
  @Field()
  birthDate?: number;
  @Field()
  birthMonth?: number;
  @Field()
  gender?: boolean;
  @Field()
  photo?: string;
  @Field({ mapping: './' })
  contact?: Contact;

  constructor(obj?: object) {
    super(obj);
  }
}
