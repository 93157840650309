import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertDialog } from '../components/alert-dialog/alert.dialog';
import { ConfirmDialog } from '../components/confirm-dialog/confirm.dialog';
import { CropPhotoDialog } from '../components/crop-photo-dialog/crop-photo.dialog';
import { InputDialog } from '../components/input-dialog/input-dialog';
import { TakePhotoDialog } from '../components/take-photo-dialog/take-photo.dialog';
import { TimeoutDialogComponent } from '../components/timeout-dialog/timeout-dialog.component';

export const DIALOG_WIDTH = '500px';

export interface PhotoDialogData {
  file: File | Blob;
  dataUrl: string;
  base64String: string | ArrayBuffer;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService implements OnDestroy {
  protected onDestroy$: Subject<void> = new Subject<void>();
  private readonly DIALOG_NORMAL_WIDTH = '450px';

  constructor(protected dialog: MatDialog) {}

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  openAlert(title: string, message: string): Promise<void> {
    return new Promise<void>((resolve) => {
      this.dialog
        .open(AlertDialog, {
          width: this.DIALOG_NORMAL_WIDTH,
          data: { title, message },
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          resolve();
        });
    });
  }

  openConfirm(title: string, message: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.dialog
        .open(ConfirmDialog, {
          width: this.DIALOG_NORMAL_WIDTH,
          data: { title, message },
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          resolve(!!result);
        });
    });
  }

  openInput(title: string, message: string, required: boolean): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.dialog
        .open(InputDialog, {
          width: this.DIALOG_NORMAL_WIDTH,
          data: { title, message, required },
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  openCaptureDialog(): Promise<PhotoDialogData> {
    return new Promise<PhotoDialogData>((resolve) => {
      this.dialog
        .open(TakePhotoDialog, {})
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  openCropDialog(dataUrl: string): Promise<PhotoDialogData> {
    return new Promise<PhotoDialogData>((resolve) => {
      this.dialog
        .open(CropPhotoDialog, {
          data: { dataUrl },
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          resolve(result);
        });
    });
  }

  openTimeout(title: string, message: string, timeout$?: Observable<string>): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.dialog
        .open(TimeoutDialogComponent, {
          width: this.DIALOG_NORMAL_WIDTH,
          data: { title, message, timeout$ },
        })
        .afterClosed()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((result) => {
          resolve(!!result);
        });
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
  }
}
