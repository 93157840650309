import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class Branch extends Model {
  @Field()
  branchName?: string;

  @Field()
  branchAddr?: string;

  @Field()
  branchPhone?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
