import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'ic-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss'],
})
export class ChipsAutocompleteComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, SEMICOLON];
  inputCtrl = new FormControl();
  filteredItems: Observable<string[]>;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() selectedItems: string[]; // = ['Lemon'];
  @Input() allItems: string[]; // = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onAdd = new EventEmitter<string>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onRemove = new EventEmitter<string>();

  @ViewChild('input', { static: false }) inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor() {
    this.filteredItems = this.inputCtrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => this._filter(item))
    );
  }

  ngOnInit(): void {
    this.disabled = !!this.disabled;
    if (!Array.isArray(this.selectedItems)) {
      this.selectedItems = [];
    }
  }

  add(event: MatChipInputEvent): void {
    // if (!this.matAutocomplete.isOpen) {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.selectedItems.push(value.trim());
      this.onAdd.emit(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.inputCtrl.setValue(null);
    // }
  }

  remove(fruit: string): void {
    const index = this.selectedItems.indexOf(fruit);

    if (index >= 0) {
      const value = this.selectedItems.splice(index, 1);
      this.onRemove.emit(value[0]);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedItems.push(event.option.viewValue);
    this.inputElement.nativeElement.value = '';
    this.inputCtrl.setValue(null);
    this.onAdd.emit(event.option.viewValue);
  }

  private _filter(value: string): string[] {
    const unSelectedItems = this.allItems.filter((item) => !this.selectedItems.includes(item));
    if (value) {
      const filterValue = value.toLowerCase();
      return unSelectedItems.filter((item) => item.toLowerCase().indexOf(filterValue) === 0);
    } else {
      return unSelectedItems;
    }
  }
}
