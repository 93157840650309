import { Entity, Field } from '../decorators/decorators';
import { User } from './user';

@Entity()
export class Customer extends User {
  @Field()
  saleId: string;
  @Field()
  assignedDoctorId: string;
  @Field()
  sharedDoctorId: string;
  @Field({ itemType: Object })
  gallery: Array<{ url: string; createdDate?: Date }>;

  @Field({ itemType: String })
  galleryFolders: Array<string>;
  @Field()
  folder: string;
  constructor(obj?: object) {
    super(obj);
  }
}
