import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';
import { PricingHistory } from './pricing-history';

@Entity()
export class Product extends Model {
  @Field()
  code?: string;

  @Field()
  productName?: string;

  @Field()
  productType?: string;

  @Field()
  description?: string;

  @Field()
  unit?: string;

  @Field({ itemType: PricingHistory })
  pricingHistory?: Array<PricingHistory>;

  @Field()
  price?: number;

  @Field()
  serviceProduct?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
