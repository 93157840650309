import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../models/user';

@Component({
  selector: 'ic-users-select',
  templateUrl: './users-select.component.html',
  styleUrls: ['./users-select.component.scss'],
})
export class UsersSelectComponent implements OnInit {
  @Input()
  placeholder: string;

  // tslint:disable-next-line:no-input-rename
  @Input('id')
  wrapperId: string;
  @Input()
  selectOne: boolean;
  @Input()
  optionUsers: Array<User>;
  @Input()
  selectedUsers: Array<User>;
  @Input()
  lazyLoadUsersMethod: (query: string) => Array<User>;

  // tslint:disable-next-line:no-output-native no-output-on-prefix no-output-rename
  @Output('change')
  onChange = new EventEmitter<Array<User>>();
  @Output()
  selectedUsersChange = new EventEmitter<Array<User>>();

  constructor() {}

  ngOnInit() {}

  changeSelectedUsers() {
    this.selectedUsersChange.emit(this.selectedUsers);
    this.onChange.emit(this.selectedUsers);
  }
}
