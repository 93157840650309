// https://codecraft.tv/courses/angular/components/viewchildren-and-contentchildren/
import {
  AfterViewInit,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { WidgetHeaderComponent } from '../widget-header/widget-header.component';

@Component({
  selector: 'ic-widget',
  templateUrl: './widget.component.html',
  styleUrls: ['./widget.component.scss'],
})
export class WidgetComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() allowFullScreen: boolean | string = false;
  @Output() exitFullScreen = new EventEmitter<any>();
  @Output() expandFullScreen = new EventEmitter<any>();
  @HostBinding('class.full-screen') public isFullScreen: boolean;
  @ContentChild(WidgetHeaderComponent, { static: false }) widgetHeader: WidgetHeaderComponent;

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    const allowFullScreen: SimpleChange = changes.allowFullScreen;
    if (allowFullScreen.currentValue !== allowFullScreen.previousValue) {
      this.initScreen(allowFullScreen.currentValue);
    }
  }

  ngAfterViewInit(): void {
    this.allowFullScreen = [true, 'true', 'TRUE'].includes(this.allowFullScreen);
    this.initScreen(this.allowFullScreen);
    //
    if (this.widgetHeader) {
      this.widgetHeader.onExpandFullScreen = () => {
        this.isFullScreen = true;
        document.body.classList.add('lock-scroll');
        setTimeout(() => {
          this.expandFullScreen.emit();
        });
      };
      //
      this.widgetHeader.onExitFullScreen = () => {
        this.isFullScreen = false;
        document.body.classList.remove('lock-scroll');
        setTimeout(() => {
          this.exitFullScreen.emit();
        });
      };
    }
  }

  ngOnInit() {}

  private initScreen(allowFullScreen: boolean | string) {
    if (!!this.widgetHeader) {
      allowFullScreen = [true, 'true', 'TRUE'].includes(this.allowFullScreen);
      setTimeout(() => {
        this.widgetHeader.setAllowFullScreen(allowFullScreen as boolean);
      }, 250);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    if (this.isFullScreen) {
      event.preventDefault();
    }
  }
}
