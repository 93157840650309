import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class Equipment extends Model {
  @Field()
  equipmentName?: string;
  @Field()
  equipmentType?: string;
  @Field()
  equipmentLocation?: string;
  @Field()
  equipmentStatus?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
