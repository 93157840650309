import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Autowired, PayslipCalculator, UsageHistory } from '../../ic-core';
import { PayslipRepository } from '../repositories/payslip.repository';

@Injectable({
  providedIn: 'root',
})
export class PayslipService {
  @Autowired()
  protected payslipRepository: PayslipRepository;


  getPayslip(payslip: PayslipCalculator): Promise<PayslipCalculator> {
    return this.payslipRepository.create(payslip);
  }
}
