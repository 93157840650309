import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class InventoryHistory extends Model {
  @Field()
  productId?: string;

  @Field()
  transactionDate?: Date;

  @Field()
  amount?: number;

  @Field()
  creditAmount?: number;

  @Field()
  debitAmount?: number;

  @Field()
  type?: string;

  @Field()
  targetId?: string;

  @Field()
  customerId?: string;

  @Field()
  inventoryLocation?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
