import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import { Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { User } from '../..';

@Component({
  selector: 'ic-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
})
export class UserSelectComponent implements OnChanges, OnInit {
  @Input()
  users: Array<User>;
  @Input()
  placeholder: string;

  // tslint:disable-next-line:no-input-rename
  @Input('id')
  wrapperId: string;
  @Input()
  selectedUser: User;

  @Input()
  showAvatar: boolean | string;

  @Input()
  queryAllUsers: boolean | string;

  @Input()
  lazyLoadUsersMethod: (query: string) => Array<User>;

  @Input()
  showAsterisk: boolean;

  @Input()
  showId: boolean;

  @Input()
  disabled = false;

  // tslint:disable-next-line:no-output-native no-output-on-prefix no-output-rename
  @Output('change')
  onChange = new EventEmitter<User>();
  @Output()
  selectedUserChange = new EventEmitter<User>();
  autoCompleteControl = new FormControl();
  filteredOptions: Observable<User[]>;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedUser &&
      !changes.selectedUser.firstChange &&
      changes.selectedUser.currentValue &&
      changes.selectedUser.previousValue &&
      changes.selectedUser.currentValue.id !== changes.selectedUser.previousValue.id
    ) {
      this.autoCompleteControl.setValue(this.selectedUser);
    }
    if (changes.disabled) {
      if (changes.disabled.currentValue) {
        this.autoCompleteControl.disable();
      } else {
        this.autoCompleteControl.enable();
      }
    }
  }

  ngOnInit() {
    this.queryAllUsers = ['true', 'TRUE', true].includes(this.queryAllUsers);
    this.showAvatar = !['false', 'FALSE', false].includes(this.showAvatar);
    if (!!this.lazyLoadUsersMethod) {
      this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
        startWith(''),
        // delay emits
        debounceTime(300),
        // use switch map so as to cancel previous subscribed events, before creating new once
        switchMap((value) => {
          if (!value || typeof value !== 'string') {
            // if no value is pressent, return null
            return of(null);
          } else {
            this.selectedChange(new User({ fullName: value }));
            // lookup from service
            return this.lazyLoadUsersMethod(value);
          }
        })
      );
    } else {
      this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
        startWith(''),
        map((value) =>
          !!value && typeof value === 'string'
            ? this._filter(value)
            : this.searchWithEmptyCondition()
        )
      );
    }
  }

  private _filter(value: string): User[] {
    if (!value || typeof value !== 'string') {
      return this.queryAllUsers ? [...this.users] : [];
    }
    const filterValue = value.toLowerCase();
    const result = [
      ...this.users.filter((user) => {
        return (
          user.fullName.toLowerCase().indexOf(filterValue) === 0 ||
          (!!user.contact &&
            !!user.contact.phones &&
            !!user.contact.phones[0] &&
            !!user.contact.phones[0].number &&
            user.contact.phones[0].number.indexOf(filterValue) === 0)
        );
      }),
      ...this.users.filter((user) => {
        return (
          user.fullName.toLowerCase().indexOf(filterValue) > 0 ||
          (!!user.contact &&
            !!user.contact.phones &&
            !!user.contact.phones[0] &&
            !!user.contact.phones[0].number &&
            user.contact.phones[0].number.indexOf(filterValue) > 0)
        );
      }),
    ];
    this.selectedChange(new User({ fullName: value }));
    return result;
  }

  private searchWithEmptyCondition() {
    if (!!this.lazyLoadUsersMethod) {
      return [];
    } else {
      return this.queryAllUsers ? [...this.users] : [];
    }
  }

  optionSelected($event: MatAutocompleteSelectedEvent) {
    console.log('optionSelected -> ');
    this.selectedChange($event.option.value);
  }

  selectedChange(user: User) {
    this.selectedUser = user;
    this.selectedUserChange.emit(this.selectedUser);
    this.onChange.emit(this.selectedUser);
  }

  displayFn(user?: User): string {
    return user && user.fullName
      ? user.fullName
      : this.selectedUser
      ? this.selectedUser.fullName
      : '';
  }

  change($event: Event) {}
}
