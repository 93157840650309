import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

const canvasToBlob = (canvas) => {
  return new Promise<Blob>((resolve) => {
    canvas.toBlob(
      (blob) => {
        resolve(blob);
      },
      'image/png',
      1
    );
  });
};

@Component({
  selector: 'ic-camera-stream',
  templateUrl: './camera-stream.component.html',
  styleUrls: ['./camera-stream.component.scss'],
})
export class CameraStreamComponent implements OnInit, AfterViewInit {
  @Input() width: number;
  @Input() height: number;

  @Output() taken = new EventEmitter<{
    file: File | Blob;
    dataUrl: string;
    base64String: string | ArrayBuffer;
  }>();

  @ViewChild('video', { static: false }) videoElementRef: ElementRef;
  @ViewChild('canvas', { static: false }) canvasElementRef: ElementRef;
  // @ViewChild('photo', {static: false}) photoElementRef: ElementRef;
  @ViewChild('startButton', { static: false }) startButtonElementRef: ElementRef;

  video: any;
  canvas: any;
  photo: any;
  startButton: any;
  streaming = false;

  constructor() {}

  ngOnInit() {
    this.width = this.width || 400;
    this.height = this.height || 400;
  }

  async ngAfterViewInit() {
    this.video = this.videoElementRef.nativeElement;
    this.video = this.videoElementRef.nativeElement;
    this.canvas = this.canvasElementRef.nativeElement;
    // this.photo = this.photoElementRef.nativeElement;
    this.startButton = this.startButtonElementRef.nativeElement;

    try {
      //// Can be 'user' or 'environment' to access back or front camera (NEAT!)
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'user' },
        audio: false,
      });
      this.video.srcObject = stream;
      this.video.play();
    } catch (err) {
      console.log('An error occurred: ' + err);
    }
    this.clearPhoto();
  }

  canplay(ev) {
    if (!this.streaming) {
      this.video.setAttribute('width', this.width);
      this.video.setAttribute('height', this.height);
      this.canvas.setAttribute('width', this.width);
      this.canvas.setAttribute('height', this.height);
      this.streaming = true;
    }
  }

  async takePicture(event) {
    const context = this.canvas.getContext('2d');
    this.canvas.width = this.video.videoWidth; // this.width;
    this.canvas.height = this.video.videoHeight; // this.height;
    context.drawImage(this.video, 0, 0); // , this.width, this.height);
    // context.drawImage(this.video, 0, 0, this.width);

    const base64String = this.canvas.toDataURL('image/png');
    const file = await canvasToBlob(this.canvas);
    const dataUrl = URL.createObjectURL(file);

    this.taken.emit({ file, dataUrl, base64String });
    event.preventDefault();
  }

  clearPhoto() {
    const context = this.canvas.getContext('2d');
    context.fillStyle = '#AAA';
    context.fillRect(0, 0, this.canvas.width, this.canvas.height);

    const data = this.canvas.toDataURL('image/png');
    // this.photo.setAttribute('src', data);
  }
}
