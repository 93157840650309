import { Entity, Field } from '../decorators/decorators';
import { InvoicePayment } from './invoice-payment';
import { Model, Status } from './model';

@Entity()
export class Invoice extends Model {
  @Field()
  orderNumber?: string;
  @Field()
  orderAmount?: number;
  @Field()
  debtAmount?: number;
  @Field()
  additionalAmount?: number;
  @Field()
  totalAmount?: number;
  @Field()
  paidAmount?: number;
  @Field()
  billingType?: string;
  @Field()
  currency?: string;
  @Field()
  exchangeRate?: number;
  @Field()
  bankName?: string;
  @Field()
  billingStatus?: Status.TypeEnum;
  @Field()
  signatureBase64?: string;
  @Field()
  customerId?: string;
  @Field()
  employeeId?: string;
  @Field()
  debtInvoiceId?: string;
  @Field()
  notes?: string;
  @Field({ itemType: InvoicePayment })
  payments: Array<InvoicePayment>;
  @Field()
  branch?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
