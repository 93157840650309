import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ic-sign-canvas',
  templateUrl: './sign-canvas.component.html',
  styleUrls: ['./sign-canvas.component.scss'],
})
export class SignCanvasComponent implements OnChanges, AfterContentInit {
  @Input() readonly: boolean;
  @Input() source: string; // base64String
  //
  @Output() sourceChange = new EventEmitter<string>();
  @Output() signed = new EventEmitter<string>();
  //
  @ViewChild('signCanvas', { static: false }) signCanvasElementRef: ElementRef;
  signCanvas;
  signCanvasContext;
  //
  drawing;
  mousePosition;
  lastPosition;

  constructor() {
    this.readonly = this.readonly || false;
  }

  ngOnChanges(changes: SimpleChanges) {
    const source: SimpleChange = changes.source;
    if (source && source.currentValue !== source.previousValue) {
      this.drawImage(source.currentValue);
    }
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.signCanvas = this.signCanvasElementRef.nativeElement;
      this.clearCanvas();
      this.initSignCanvasContext();
      this.initPosition();
      this.drawImage(this.source);
    }, 500);
  }

  private drawImage(source: string) {
    if (!!this.signCanvasContext) {
      this.clearCanvas();
      this.initSignCanvasContext();
      if (!!source && source !== this.signCanvas.toDataURL()) {
        const image = new Image();
        image.onload = () => {
          this.signCanvasContext.drawImage(image, 0, 0);
        };
        image.src = source;
      }
    }
  }

  private initSignCanvasContext() {
    const ctx = this.signCanvas.getContext('2d');
    ctx.strokeStyle = '#0000ff';
    ctx.lineWidth = 2;
    this.signCanvasContext = ctx;
  }

  private initPosition() {
    this.drawing = false;
    this.lastPosition = this.mousePosition = { x: 0, y: 0 };
  }
  // Get a regular interval for drawing to the screen

  //
  private async renderCanvas() {
    if (this.drawing) {
      this.signCanvasContext.moveTo(this.lastPosition.x, this.lastPosition.y);
      this.signCanvasContext.lineTo(this.mousePosition.x, this.mousePosition.y);
      this.signCanvasContext.stroke();
      this.lastPosition = this.mousePosition;

      this.source = await this.captureSignImage();
      this.sourceChange.emit(this.source);
    }
  }

  //
  clearCanvas() {
    const rect = this.signCanvas.getBoundingClientRect();
    this.signCanvas.width = rect.width;
    this.signCanvas.height = rect.height;
  }

  private async endDrawing() {
    this.drawing = false;
    await this.done();
  }

  private async done() {
    this.source = await this.captureSignImage();
    this.sourceChange.emit(this.source);
    this.signed.emit(this.source);
  }

  private async captureSignImage() {
    return new Promise<string>((resolve) => {
      this.signCanvas.toBlob((file: Blob) => {
        resolve(this.signCanvas.toDataURL());
      });
    });
  }

  private getMousePosition(event) {
    const rect = this.signCanvas.getBoundingClientRect();
    console.log(`event.clientX: ${event.clientX}`);
    console.log(`event.clientY: ${event.clientY}`);
    console.log(`event.pageX: ${event.pageX}`);
    console.log(`event.pageY: ${event.pageY}`);

    console.log(`rect.left: ${rect.left}`);
    console.log(`rect.top: ${rect.top}`);

    const point = {
      x: event.clientX - rect.left, // - window.pageXOffset,
      y: event.clientY - rect.top, // - window.pageYOffset
    };
    return point;
  }

  /*
    private getCursorPos(event) {
  const rect = this.secondImageContainer.getBoundingClientRect();
  let x = event.pageX - rect.left;
  x = x - window.pageXOffset;
  return x;
}
   */

  // listener canvas events{
  mouseDown(event) {
    event.preventDefault();
    this.drawing = true;
    this.lastPosition = this.mousePosition = this.getMousePosition(event);
  }

  mouseMove(event) {
    if (this.drawing) {
      this.mousePosition = this.getMousePosition(event);
      this.renderCanvas();
    }
  }

  mouseUp(event) {
    this.endDrawing();
  }

  mouseLeave(event) {
    if (this.drawing) {
      this.endDrawing();
    }
  }

  // } listener canvas events

  // Set up touch events for mobile {
  touchStart(touchEvent) {
    touchEvent.preventDefault();
    this.mousePosition = this.getTouchPos(touchEvent);
    const touch = touchEvent.touches[0];
    const mouseEvent = new MouseEvent('mousedown', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    this.signCanvas.dispatchEvent(mouseEvent);
  }

  touchMove(touchEvent) {
    touchEvent.preventDefault();
    const touch = touchEvent.touches[0];
    const mouseEvent = new MouseEvent('mousemove', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    this.signCanvas.dispatchEvent(mouseEvent);
  }

  touchEnd(touchEvent) {
    touchEvent.preventDefault();
    const mouseEvent = new MouseEvent('mouseup', {});
    this.signCanvas.dispatchEvent(mouseEvent);
  }

  touchLeave(touchEvent) {
    touchEvent.preventDefault();
    const touch = touchEvent.touches[0];
    const mouseEvent = new MouseEvent('mouseleave', {
      clientX: touch.clientX,
      clientY: touch.clientY,
    });
    this.signCanvas.dispatchEvent(mouseEvent);
  }
  // Get the position of a touch relative to the canvas
  getTouchPos(touchEvent) {
    const rect = this.signCanvas.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top,
    };
  }

  // } Set up touch events for mobile
  /*
  // Prevent scrolling when touching the canvas
  @HostListener('window:touchstart', ['$event'])
  windowTouchstart(event) {
    if (event.target === this.signCanvas) {
      // alert('window:touchstart');
      event.preventDefault();
    }
  }

  @HostListener('window:touchend', ['$event'])
  windowTouchend(event) {
    if (event.target === this.signCanvas) {
      event.preventDefault();
    }
  }

  @HostListener('window:touchmove', ['$event'])
  windowTouchmove(event) {
    if (event.target === this.signCanvas) {
      event.preventDefault();
      event.stopPropagation(); event.stopImmediatePropagation();
    }
  }
*/
  // } Prevent scrolling when touching the canvas
}
