import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class Expense extends Model {
  @Field()
  sender?: string;

  @Field()
  receiver?: string;

  @Field()
  amount?: number;

  @Field()
  description?: string;

  @Field()
  expendingDate?: Date;

  @Field()
  comment?: string;

  @Field()
  branch?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
