import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'ic-take-photo',
  templateUrl: './take-photo.component.html',
  styleUrls: ['./take-photo.component.scss'],
})
export class TakePhotoComponent implements OnInit {
  @Output() took = new EventEmitter<{
    file: File | Blob;
    dataUrl: string;
    base64String: string | ArrayBuffer;
  }>();
  constructor(private dialogService: DialogService) {}

  ngOnInit() {}

  async open() {
    const capturedData = await this.dialogService.openCaptureDialog();
    const croppedData = await this.dialogService.openCropDialog(capturedData.dataUrl);
    this.took.emit(croppedData);
  }
}
