import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmDialog } from '../..';

@Component({
  selector: 'ic-alert-dialog',
  templateUrl: './alert.dialog.html',
  styleUrls: ['./alert.dialog.scss'],
})
// tslint:disable-next-line:component-class-suffix
export class AlertDialog implements OnInit {
  title: string;
  message: string;

  constructor(
    public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {
    this.dialogRef.disableClose = true;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    // @ts-ignore
    this.message = this.data.message || '';
    // @ts-ignore
    this.title = this.data.title || '';
  }
}
