import { Entity, Field } from '../decorators/decorators';
import { Branch } from './branch';
import { Contact } from './contact';
import { Customer } from './customer';
import { Employee } from './employee';
import { Model } from './model';
import { Phone } from './phone';
import { Service } from './service';

@Entity()
export class Appointment extends Model {
  @Field()
  startTime: Date;
  @Field()
  endTime: Date;

  @Field()
  receptionist: Employee;
  @Field()
  therapist: Employee;
  @Field()
  technician: Employee;
  @Field()
  customer: Customer;
  @Field()
  branch: Branch;
  @Field({ itemType: Service })
  services: Array<Service>;
  @Field({ mapping: 'appointmentStatus' })
  status: Appointment.StatusEnum;
  //
  @Field()
  receptionistId: string;
  @Field()
  therapistId: string;
  @Field()
  technicianId: string;
  @Field()
  customerId: string;
  @Field()
  customerFullName: string;
  @Field()
  customerPhoneNumber: string;
  //
  constructor(obj?: object) {
    super(obj);
  }

  fromRequestData(data: object): void {
    super.fromRequestData(data);

    this.receptionist = new Employee({ id: this.receptionistId });
    this.therapist = new Employee({ id: this.therapistId });
    this.technician = new Employee({ id: this.technicianId });
    this.customer = new Customer({ id: this.customerId });
    this.customer.fullName = this.customerFullName;
    if (this.customerPhoneNumber) {
      this.customer.contact = new Contact({});
      this.customer.contact.phones = [new Phone({ number: this.customerPhoneNumber })];
    }
  }

  toRequestData(): object {
    this.receptionistId = this.receptionist ? this.receptionist.id : undefined;
    this.therapistId = this.therapist ? this.therapist.id : undefined;
    this.technicianId = this.technician ? this.technician.id : undefined;

    if (this.customer) {
      if (this.customer.id) {
        this.customerId = this.customer.id;
      } else {
        this.customerFullName = this.customer.fullName;
        if (
          this.customer.contact &&
          this.customer.contact.phones &&
          this.customer.contact.phones.length
        ) {
          this.customerPhoneNumber = this.customer.contact.phones[0].number;
        }
      }
    }

    this.receptionist = undefined;
    this.therapist = undefined;
    this.technician = undefined;
    this.customer = undefined;

    return super.toRequestData();
  }
}

// tslint:disable-next-line:no-namespace
export namespace Appointment {
  export type StatusEnum = 'accepted' | 'deleted';
  export const StatusEnum = {
    Accepted: 'accepted' as StatusEnum,
    Deleted: 'deleted' as StatusEnum,
  };
}
