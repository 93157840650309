import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { PageLoaderComponent } from '../components/page-loader/page-loader.component';

@Injectable({
  providedIn: 'root',
})
export class PageLoaderService {
  private pageLoaderComponent: PageLoaderComponent;
  private loaderCounter = 0;

  constructor(private router: Router) {
    setTimeout(() => {
      this.listenRouterChange();
    }, 1000);
  }

  private listenRouterChange(): void {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.showPageLoader();
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.hidePageLoader();
          break;
        }
        default: {
          break;
        }
      }
    });
  }

  public showPageLoader() {
    if (this.loaderCounter < 0) {
      this.loaderCounter = 0;
    }
    this.loaderCounter++;
    this.pageLoaderComponent.show();
  }

  public hidePageLoader() {
    if (this.loaderCounter > 0) {
      this.loaderCounter--;
    }
    if (this.loaderCounter === 0) {
      this.pageLoaderComponent.hide();
    }
  }
}
