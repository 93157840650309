import { Entity, Field } from '../decorators/decorators';
import { Employee } from './employee';
import { Model } from './model';
import { PricingHistory } from './pricing-history';

export enum ServiceProductOption {
  Y = 'Y',
  N = 'N',
}

@Entity()
export class Service extends Model {
  @Field()
  code?: string;

  @Field()
  serviceName?: string;

  @Field()
  description?: string;

  @Field()
  unit?: string;

  @Field({ itemType: PricingHistory })
  pricingHistory?: Array<PricingHistory>;

  @Field()
  price?: number;

  @Field()
  serviceProduct?: ServiceProductOption;

  @Field({ itemType: String })
  employeeTypes?: Array<string>;

  @Field()
  productCode?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
