import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class Permission extends Model {
  @Field()
  permissionName?: string;

  @Field()
  permissionString?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
