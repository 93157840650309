import { Entity, Field } from '../decorators/decorators';
import { EmbeddedProductItem } from './embeddedProductItem';
import { EmbeddedServiceItem } from './embeddedServiceItem';
import { Model } from './model';

export enum OrderHistoryDisplayType {
  SERVICE = 'Service',
  PRODUCT = 'Product',
}

@Entity()
export class OrderedItem extends Model {
  @Field()
  type?: string;
  @Field()
  code?: string;
  @Field()
  unit?: string;
  @Field()
  unitPrice?: number;
  @Field()
  compensationAmount?: number;
  @Field()
  quantity?: number;
  @Field()
  discountPercentage?: number;
  @Field()
  totalAmount?: number;

  @Field()
  // tslint:disable-next-line:variable-name
  _embeddedService?: EmbeddedServiceItem;

  @Field()
  // tslint:disable-next-line:variable-name
  _embeddedProduct?: EmbeddedProductItem;

  @Field()
  displayName?: string;

  constructor(obj?: object) {
    super(obj);
  }
}

// tslint:disable-next-line:no-namespace
export namespace ItemType {
  export type TypeEnum =
    | 'ServiceType'
    | 'ProductType'
    | 'PackageType'
    | 'ExternalProductType'
    | 'GiftType';
  export const TypeEnum = {
    ServiceType: 'Service' as TypeEnum,
    ProductType: 'Product' as TypeEnum,
    PackageType: 'Package' as TypeEnum,
    ExternalProductType: 'ExternalProduct' as TypeEnum,
    GiftType: 'Gift' as TypeEnum,
  };
}
