import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ic-sign-canvas-widget',
  templateUrl: './sign-canvas-widget.component.html',
  styleUrls: ['./sign-canvas-widget.component.scss'],
})
export class SignCanvasWidgetComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() source: string; // base64String
  //
  @Output() sourceChange = new EventEmitter<string>();
  @Output() signed = new EventEmitter<string>();

  constructor() {
    this.readonly = this.readonly || false;
  }

  ngOnInit() {}

  handleSourceChange() {
    this.sourceChange.emit(this.source);
  }
  handleCanvasSigned() {
    this.signed.emit(this.source);
  }

  clear() {
    this.source = '';
    this.sourceChange.emit(this.source);
    this.signed.emit(this.source);
  }
}
