import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class InvoicePayment extends Model {
  @Field()
  amount?: number;
  @Field()
  billingType?: string;
  @Field()
  currency?: string;
  @Field()
  exchangeRate?: number;
  @Field()
  bankName?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
