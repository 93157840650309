import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class ShortEmployee extends Model {
  constructor(obj?: object) {
    super(obj);
  }

  @Field()
  employeeId?: string;

  @Field()
  fullName?: string;
}
