import { Entity, Field } from '../decorators/decorators';
import { Contact } from './contact';
import { Model } from './model';

@Entity()
export class Company extends Model {
  @Field()
  companyName?: string;

  @Field()
  accountNumber?: string;

  @Field()
  bankName?: string;

  @Field()
  holderName?: string;

  @Field()
  contact: Contact;

  constructor(obj?: object) {
    super(obj);
  }
}
