import { Entity, Field } from '../decorators/decorators';
import { Absence } from './absence';
import { Branch } from './branch';
import { Permission } from './permission';
import { Role } from './role';
import { SaleTargetSet } from './sale-target-set';
import { User } from './user';
import { WorkShift } from './work-shift';

@Entity()
export class Employee extends User {
  constructor(obj?: object) {
    super(obj);
  }

  @Field()
  title?: string;
  @Field()
  experience?: number;
  @Field()
  baseSalary?: string;
  @Field({ mapping: 'employeeType' })
  type?: Employee.Types;
  @Field()
  signature?: string;
  @Field()
  branch: Branch;
  @Field({ itemType: Role })
  roles?: Array<Role>;
  @Field({ itemType: Absence })
  absences?: Array<Absence>;
  @Field({ itemType: WorkShift })
  shiftAllocations?: Array<WorkShift>;
  @Field({ itemType: SaleTargetSet })
  saleTargetSets?: Array<SaleTargetSet>;

  isAdmin(): boolean {
    const permissionsArray: Permission[] = [].concat(...this.roles.map((role) => role.permissions));
    return permissionsArray
      .map((permission) => permission.permissionString)
      .some((permissionString) => permissionString === '*.*.*');
  }

  isLeadOrManager(): boolean {
    return [Employee.Titles.Manager, Employee.Titles.Lead].includes(
      Employee.Titles[this.capitalizeWord(this.title)]
    );
  }

  private capitalizeWord(word: string): string {
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
}

// tslint:disable-next-line:no-namespace
export namespace Employee {
  export type Types = 'doctor' | 'seller' | 'technician' | 'accountant';
  export const Types = {
    Doctor: 'doctor' as Types,
    Seller: 'seller' as Types,
    Technician: 'technician' as Types,
    Accountant: 'accountant' as Types,
  };

  export type Titles = 'manager' | 'lead' | 'member';
  export const Titles = {
    Manager: 'manager' as Titles,
    Lead: 'lead' as Titles,
    Member: 'member' as Titles,
  };

  export const TitleMapping = {
    Doctor: ['lead' as Titles, 'member' as Titles],
    Seller: ['lead' as Titles, 'member' as Titles],
    Technician: ['member' as Titles],
    Accountant: ['member' as Titles],
    Superadmin: ['manager' as Titles],
  };
}
