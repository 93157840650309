import { Entity, Field } from '../decorators/decorators';
import { Customer } from './customer';
import { Model } from './model';

@Entity()
export class CustomerDebt extends Model {
  @Field()
  customerId?: string;

  @Field()
  amount?: number;

  @Field()
  lastTransactionDate?: Date;

  @Field()
  // tslint:disable-next-line:variable-name
  _embeddedCustomer?: Customer;

  @Field()
  recordedDate?: Date;

  @Field()
  creditAmount?: number;

  @Field()
  debitAmount?: number;

  @Field()
  comment?: string;

  @Field()
  employeeId?: string;

  @Field()
  targetId?: string;
  @Field()
  type?: string;
  @Field()
  billingNote: string;

  constructor(obj?: object) {
    super(obj);
  }
}
