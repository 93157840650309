import { Entity, Field } from '../decorators/decorators';
import { Address } from './address';
import { Model } from './model';
import { Phone } from './phone';

@Entity()
export class Contact extends Model {
  @Field()
  email?: string;
  @Field()
  primaryPhone?: string;
  @Field()
  secondaryPhone?: string;
  @Field({ itemType: Address })
  addresses?: Array<Address>;

  get phones(): Array<Phone> {
    const self = this;

    const primaryPhone = new Phone();
    const secondaryPhone = new Phone();

    Object.defineProperty(primaryPhone, 'number', {
      get: () => {
        return self.primaryPhone;
      },
      set: (val) => {
        self.primaryPhone = val;
      },
    });

    Object.defineProperty(secondaryPhone, 'number', {
      get: () => {
        return self.secondaryPhone;
      },
      set: (val) => {
        self.secondaryPhone = val;
      },
    });

    return [primaryPhone, secondaryPhone];
  }

  set phones(phones: Array<Phone>) {
    const self = this;
    if (Array.isArray(phones)) {
      if (phones.length > 0) {
        // tslint:disable-next-line:no-shadowed-variable
        const primaryPhone = phones[0];
        self.primaryPhone = primaryPhone.number;
        delete primaryPhone.number;
        Object.defineProperty(primaryPhone, 'number', {
          get: () => {
            return self.primaryPhone;
          },
          set: (val) => {
            self.primaryPhone = val;
          },
        });

        if (phones.length > 1) {
          const secondaryPhone = phones[0];
          self.secondaryPhone = secondaryPhone.number;
          delete secondaryPhone.number;
          Object.defineProperty(secondaryPhone, 'number', {
            get: () => {
              return self.secondaryPhone;
            },
            set: (val) => {
              self.secondaryPhone = val;
            },
          });
        }
      }
    }
  }

  constructor(obj?: object) {
    super(obj);
    const field = 'phones';
    this.phones = obj[field];
  }
}
