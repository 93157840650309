import { Entity, Field } from '../decorators/decorators';
import { Customer } from './customer';
import { Model } from './model';

@Entity()
export class FollowUp extends Model {
  @Field()
  customer?: Customer;
  @Field()
  targetDate?: Date;
  @Field()
  taskStatus?: FollowUp.Status;
  @Field({ itemType: String })
  notes?: Array<string>;
  @Field()
  link: { property: string; object: any };

  constructor(obj?: object) {
    super(obj);
  }
}
// tslint:disable-next-line:no-namespace
export namespace FollowUp {
  export type Status = 'Planned' | 'Failed' | 'Completed';
  export const Status = {
    Planned: 'Planned' as Status,
    Failed: 'Failed' as Status,
    Completed: 'Completed' as Status,
  };
}
