import { Entity, Field } from '../decorators/decorators';
import { Customer } from './customer';
import { Model } from './model';

@Entity()
export class Payment extends Model {
  @Field()
  billingNumber?: string;
  @Field()
  customerNumber?: string;
  @Field()
  paidAmount?: number;
  @Field()
  billingType?: string;
  @Field()
  customer?: Customer;
  @Field()
  paymentStatus?: PaymentStatus.TypeEnum;
  @Field()
  employeeId?: string;
  @Field()
  actualPayment?: number;

  constructor(obj?: object) {
    super(obj);
  }
}

// tslint:disable-next-line:no-namespace
export namespace PaymentStatus {
  export type TypeEnum = 'done' | 'processing' | 'reject' | 'partial';
  export const TypeEnum = {
    Done: 'done' as TypeEnum,
    Processing: 'processing' as TypeEnum,
    Partial: 'partial' as TypeEnum,
    Reject: 'reject' as TypeEnum,
  };
}
