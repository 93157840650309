import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Model } from '../..';

@Component({
  selector: 'ic-autocomplete-object',
  templateUrl: './autocomplete-object.component.html',
  styleUrls: ['./autocomplete-object.component.scss'],
})
export class AutocompleteObjectComponent<T extends Model> implements OnInit, OnChanges {
  @Input()
  key: string;
  @Input()
  title: string;
  @Input()
  placeholder: string;
  @Input()
  options: Array<T>;
  @Input()
  value: T;

  @Output()
  valueChange = new EventEmitter<T>();
  @Output()
  optionSelected = new EventEmitter<T>();

  autocompleteInputControl = new FormControl();
  @ViewChild(MatAutocompleteTrigger, { static: false }) autocompleteTrigger: MatAutocompleteTrigger;
  filteredOptions: Observable<any>;

  constructor() {}

  ngOnInit() {
    this.initFilteredOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options && changes.options.currentValue !== changes.options.previousValue) {
      this.initFilteredOptions();
    }
  }

  private initFilteredOptions() {
    if (this.options) {
      this.filteredOptions = this.autocompleteInputControl.valueChanges.pipe(
        startWith(''),
        map((str) => (str ? this._filterOptions(str) : this.options.slice()))
      );
    }
  }

  private _filterOptions(str: string) {
    if (typeof str === 'string') {
      const filterValue = str.toLowerCase();

      return [
        ...this.options.filter(
          (option) => option[this.title].toLowerCase().indexOf(filterValue) === 0
        ),
        ...this.options.filter(
          (option) => option[this.title].toLowerCase().indexOf(filterValue) > 0
        ),
      ];
    } else {
      return [...this.options];
    }
  }

  displayFn(item?: T): string {
    return item ? item[this.title] : '';
  }

  // optionChange($event: MatAutocompleteSelectedEvent) {
  //   this.value = $event.option.value;
  //   this.valueChange.emit(this.value);
  //   this.optionSelected.emit(this.value);
  // }

  modelChange(item: T) {
    if (typeof item !== 'string') {
      this.valueChange.emit(this.value);
      this.optionSelected.emit(this.value);
    }
  }

  chooseFirstOption(): void {
    if (this.autocompleteTrigger.autocomplete.isOpen) {
      const options = this.autocompleteTrigger.autocomplete.options.toArray();
      if (options.length > 0) {
        this.autocompleteInputControl.setValue(options[0].value);
        this.autocompleteTrigger.closePanel();
      }
    }
  }

  onEnter() {
    this.chooseFirstOption();
    if (this.autocompleteTrigger.autocomplete.isOpen) {
      this.autocompleteTrigger.closePanel();
    }
  }

  onBlur(event: FocusEvent) {
    const relatedTarget = event.relatedTarget;
    if (relatedTarget) {
      const parentRelateTargetId = (relatedTarget as HTMLElement).parentElement.id;
      const ariaOwns = (event.target as HTMLElement).getAttribute('aria-owns');
      if (ariaOwns === parentRelateTargetId) {
        return;
      }
    }

    if (this.autocompleteTrigger.autocomplete.isOpen) {
      this.chooseFirstOption();
      this.autocompleteTrigger.closePanel();
    }
  }
}
