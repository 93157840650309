import { Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
// https://medium.com/claritydesignsystem/ng-content-the-hidden-docs-96a29d70d11b
// https://dev.to/mustapha/angular-build-more-dynamic-components-with-ngtemplateoutlet-3nee
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'wrapper',
  styles: [
    `
      .box.red {
        border-color: red;
      }
      .box {
        border: 5px solid blue;
        padding: 20px;
        margin-bottom: 10px;
      }
    `,
  ],
  template: `
    <div class="box" *ngFor="let item of items">
      <ng-container
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
    </div>
  `,
})
// tslint:disable-next-line:component-class-suffix
export class Wrapper {
  @Input()
  items: Array<number>;
  @ContentChild(TemplateRef, { static: false }) template: TemplateRef<any>;
}
