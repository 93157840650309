import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '..';
import { HomeConfiguration } from '../../../pages/home/home.component';
import { PermissionString } from '../../authorization/interface/permission-string';
import { RoleService } from '../../authorization/services/role.service';
import { Employee } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private config = require('../../../../assets/home-applications.json');
  constructor(
    private router: Router,
    private authService: AuthService,
    private roleService: RoleService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    await this.authService.awaitLogged();
    if (this.authService.loggedInEmployee) {
      return await this.isAuthorized(route);
      // return true;
    } else {
      let returnUrl = state.url;
      if (returnUrl === '/home') {
        returnUrl = '/branch';
      }
      await this.router.navigate(['/auth/sign-in'], { queryParams: { returnUrl } });
      return false;
    }
  }

  private async isAuthorized(route: ActivatedRouteSnapshot): Promise<boolean> {
    const componentName = this.getComponentName(route);
    console.log(componentName);
    const loginUser: Employee = await this.authService.getLoggedInEmployee();

    const permissions = await this.roleService.getPermissionsByUserRole(loginUser.roles);
    const permissionStringObjects: PermissionString[] = this.roleService.getPermissionStringsOfModule(
      componentName,
      permissions
    );
    if (componentName === 'HomeComponent') {
      this.setHomeConfiguration(permissionStringObjects);
      return true;
    }
    const userProfileFunctions: string[] = [
      'EmployeeDetailContainer',
      'UserCalendarsContainer',
      'ChangePasswordComponent',
    ];
    if (
      permissionStringObjects.some((item) => ['*', 'view'].includes(item.operationName)) ||
      userProfileFunctions.includes(componentName)
    ) {
      this.roleService.setModelAttributesFromPermissionStringObjects(
        componentName,
        permissionStringObjects
      );
      return true;
    } else {
      // const errorMessage = await this.translationService.get('Authorization.Not.Authorized').toPromise();
      // await this.dialogService.openAlert('', errorMessage);
      // await this.router.navigate(['/home']);
      return true;
    }
  }

  private setHomeConfiguration(permissionStringObjects: PermissionString[]): void {
    const homeCfg: HomeConfiguration[] = this.config as HomeConfiguration[];
    if (permissionStringObjects.some((item) => item.attributeName === '*')) {
      this.roleService.setAuthenticatedApps(homeCfg);
    } else {
      const authenticatedHomeCfg: HomeConfiguration[] = [];
      homeCfg.forEach((cfg) => {
        if (permissionStringObjects.some((item) => item.attributeName === cfg.id)) {
          authenticatedHomeCfg.push(cfg);
        }
      });
      this.roleService.setAuthenticatedApps(authenticatedHomeCfg);
    }
  }

  private getComponentName(route: ActivatedRouteSnapshot): string {
    let componentName = '';
    if (route.routeConfig.component) {
      console.log(route.routeConfig.component.name);
      componentName = route.routeConfig.component.name;
    } else {
      // Make sure each each module only contains one container.
      // Ex: equipments -> EquipmentsContainer
      componentName = route.firstChild.routeConfig.component.name;
      const abnormalRouting: string[] = ['AssetsContainer', 'DebtsContainer', 'AccountsContainer'];
      // Assets includes assets & expense
      // Debts includes customer debt & supplier debt
      // Account includes customer & employee
      if (abnormalRouting.includes(componentName)) {
        // @ts-ignore
        componentName = route.firstChild.children[0].component.name.replace(
          'Component',
          'Container'
        );
      }
    }
    if (componentName === 'CustomersComponent') {
      componentName = 'CustomersContainer';
    }
    return componentName;
  }
}
