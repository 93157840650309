import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../../auth';
import { RoleService } from '../../authorization/services/role.service';

@Directive({
  selector: '[icPermits]',
})
export class PermitsDirective {
  @Input('icPermits') permits: Array<string> = [];

  private validatePermits = {};

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) {}

  @Input()
  set icPermits(
    permits: Array<string | { string: () => boolean }> | string | { string: () => boolean }
  ) {
    this.formatInputPermits(permits);
    if (this.isAcceptable()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private formatInputPermits(permits) {
    if (!permits) {
      permits = [];
    } else if (!Array.isArray(permits)) {
      permits = [permits];
    }

    permits.forEach((permit) => {
      switch (typeof permit) {
        case 'string':
          this.validatePermits[permit] = () => true;
          break;
        case 'object':
          this.validatePermits = Object.assign(this.validatePermits, permit);
      }
    });
  }

  private isAcceptable() {
    let acceptable = false;
    if (Object.keys(this.validatePermits).length === 0) {
      acceptable = true;
    }

    acceptable = this.authService.permissions.some((permission) => {
      const validatePermit = this.validatePermits[permission.trim()];
      return validatePermit && validatePermit();
    });

    // this.authService.permissions.forEach(permission => {
    //   if (typeof permission === 'string') {
    //     const validatePermit = this.validatePermits[permission.trim()];
    //     if (validatePermit && validatePermit()) {
    //       acceptable = true;
    //     }
    //   }
    // });

    return acceptable;
  }
}
