import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PageLoaderService } from '../../services/page-loader.service';

import * as jQuery from 'jquery';

@Component({
  selector: 'ic-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent implements OnInit {
  @Input() shown;
  @ViewChild('loaderWrapper', { static: true }) loaderWrapper: ElementRef;

  constructor(private pageLoaderService: PageLoaderService) {}

  ngOnInit() {
    if (!this.show) {
      this.hide();
    }
    (this.pageLoaderService as any).pageLoaderComponent = this;
  }

  public show() {
    jQuery(this.loaderWrapper.nativeElement).fadeIn('slow');
  }

  public hide() {
    jQuery(this.loaderWrapper.nativeElement).fadeOut('slow');
  }
}
