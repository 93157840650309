import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Autowired, Branch } from '../../ic-core';
import { BranchRepository } from '../repositories/branch.repository';

@Injectable({
  providedIn: 'root',
})
export class BranchService {
  private storedSessionBranches: Branch[];
  @Autowired()
  protected branchRepository: BranchRepository;

  async createBranch(branch: Branch): Promise<Branch> {
    try {
      branch = await this.branchRepository.create(branch);
    } catch (err) {
      throw err;
    }
    return branch;
  }

  async updateBranch(dataChanged: Branch): Promise<void> {
    await this.branchRepository.update(dataChanged);
  }

  async deleteBranch(id): Promise<void> {
    await this.branchRepository.delete(id);
  }

  searchBranches(query: any): Observable<Branch[]> {
    const searchAll = query === '';
    if (searchAll && this.storedSessionBranches) {
      return of(this.storedSessionBranches);
    }
    return this.branchRepository
      .search(query)
      .pipe(tap((branches) => (this.storedSessionBranches = branches)));
  }

  async getBranch(branchId: string): Promise<Branch> {
    return await this.branchRepository.get(branchId).toPromise();
  }

  generateEmptyBranch(): Branch {
    return new Branch({
      branchName: null,
    });
  }

  getStoredSessionBranches(): Branch[] {
    return this.storedSessionBranches;
  }
}
