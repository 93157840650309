import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class Consent extends Model {
  @Field()
  consentName?: string;

  @Field()
  effectiveDate?: Date;

  @Field()
  expirationDate?: Date;

  @Field()
  content?: string;

  @Field()
  comment?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
