import { Injectable } from '@angular/core';
import { AbstractRepository, PayslipCalculator } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class PayslipRepository extends AbstractRepository<PayslipCalculator> {
  constructor() {
    super(PayslipCalculator);
  }
}
