import { Entity, Field } from '../decorators/decorators';
import { Model, Status } from './model';
import { OrderedItem } from './ordered-item';

@Entity()
export class Order extends Model {
  @Field()
  purchaseDate?: Date;

  @Field({ itemType: OrderedItem })
  orderedItems: Array<OrderedItem>;

  @Field()
  orderStatus?: Status.TypeEnum;

  @Field()
  notes?: string;

  @Field()
  stpAccepted: boolean;

  @Field()
  additionalAmount: number;

  @Field()
  branch?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
