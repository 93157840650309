import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import html2canvas from 'html2canvas';
import * as jQuery from 'jquery';
import jsPDF from 'jspdf';
import { Autowired } from '../../decorators/decorators';
import { PageLoaderService } from '../../services/page-loader.service';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'ic-sprint-page',
  templateUrl: './sprint-page.component.html',
  styleUrls: ['./sprint-page.component.scss'],
})
export class SprintPageComponent implements OnInit, OnDestroy {
  @Input() size: 'A4' | 'A5';
  printPopup: any;
  widthA4 = 595; // px;
  heightA4: 842; // px;

  @ViewChild('printPage', { static: true }) printPage: ElementRef;

  @HostBinding('class.tablet-device') public isTabletDevice: boolean;

  @Autowired()
  utilityService: UtilityService;
  @Autowired()
  pageLoaderService: PageLoaderService;

  constructor() {
    this.size = this.size || 'A4';
    this.isTabletDevice = UtilityService.isTabletDevice();
  }

  ngOnInit() {
    document.body.style.background = 'rgb(204,204,204)';
    (document.getElementsByTagName('ic-header')[0] as any).classList.add('hidden');
    // document.getElementById('main-content').classList.add('hidden');
  }

  ngOnDestroy() {
    document.body.style.background = 'unset';
    (document.getElementsByTagName('ic-header')[0] as any).classList.remove('hidden');
    // document.getElementById('main-content').classList.remove('hidden');
  }

  private closePrintPopup() {
    if (this.printPopup) {
      this.printPopup.close();
    }
  }

  private openPrintPopup(url) {
    /*
    this.printPopup = window.open(url);
    this.printPopup.onbeforeunload = () => {
      this.closePrintPopup();
    };
    this.printPopup. = () => {
      this.closePrintPopup();
    };
    this.printPopup.focus(); // Required for IE
    this.printPopup.print();
    */

    const width = $(window).width() * 0.75;
    const height = $(window).height() * 0.75;
    const content = `<!DOCTYPE html>
        <html>
          <style media="print">
            @page
            {
              size: ${this.size};
              margin: 0px;
            }
            body, html
            {
              margin: 0px;
              padding: 0px;
              text-align: center;
            }
           </style>
      <head><title></title> </head>
      <body onload="window.focus(); window.print();" onafterprint="window.close();" style="margin: 0px; padding: 0px">
        <img src="${url}" + style="height: 99%; width: 99%;margin: auto" />
      </body>
      </html>`;
    const options =
      'toolbar=no,location=no,directories=no,menubar=no,scrollbars=yes,width=' +
      width +
      ',height=' +
      height;
    const printWindow = window.open('', 'print', options);
    printWindow.document.open();
    printWindow.document.write(content);
    printWindow.document.close();
    printWindow.focus();
  }

  private async generatePdf(canvas, fileName) {
  // const width = doc.internal.pageSize.getWidth();
    // const height = doc.internal.pageSize.getHeight();
    const widthA4 = 211; // mm;
    const heightA4 = 298; // mm;

    const doc = new jsPDF({
      unit: 'mm',
      format: 'a4',
    });
    await this.generatePdfImage(doc, canvas, widthA4, heightA4);
    doc.save(fileName + '.pdf');
  }

  private async generatePdfA5(canvas, fileName) {
      const widthA5 = 148; // mm;
      const heightA5 = 210; // mm;
      const image = canvas.toDataURL();
      const doc = new jsPDF({
        unit: 'mm',
        format: 'a5',
      });
      await this.generatePdfImage(doc, canvas, widthA5, heightA5);
      doc.save(fileName + '.pdf');
    }

  private scrollTop(): Promise<void> {
    return new Promise((resolve) => {
      jQuery('html, body').animate({ scrollTop: 0 }, 600);
      setTimeout(resolve, 600);
    });
  }

  public async print(fileName: string) {
    this.pageLoaderService.showPageLoader();
    await this.scrollTop();
    const canvas = await html2canvas(this.printPage.nativeElement, { scale: 1 });
    if (UtilityService.isTabletDevice()) {
      if (this.size === 'A4') {
        this.generatePdf(canvas, fileName);
      } else {
        this.generatePdfA5(canvas, fileName);
      }
    } else {
      //this.openPrintPopup(canvas.toDataURL());
      if (this.size === 'A4') {
        this.openPrintPopup(await this.generatePdfImageA4(canvas));
      } else {
        this.openPrintPopup(await this.generatePdfImageA5(canvas));
      }
    }
    this.pageLoaderService.hidePageLoader();
  }

  private async generatePdfImageA4(canvas) {
    const widthA4 = 211; // mm;
    const heightA4 = 298; // mm;

    const doc = new jsPDF({
      unit: 'mm',
      format: 'a4',
    });
    return await this.generatePdfImage(doc, canvas, widthA4, heightA4);
  }

  private async generatePdfImageA5(canvas) {
    const widthA5 = 148; // mm;
    const heightA5 = 210; // mm;

    const doc = new jsPDF({
      unit: 'mm',
      format: 'a5',
    });
    return await this.generatePdfImage(doc, canvas, widthA5, heightA5);
  }

  private async generatePdfImage(doc, canvas, pageWidth, pageHeight) {
    const image = canvas.toDataURL();
    let imgHeight = canvas.height * pageWidth / canvas.width;
    let imgWidth = pageWidth;
    let heightLeft = imgHeight;
    let position = 0;

    doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(image, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    return image;
  }
}
