import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-widget-body',
  templateUrl: './widget-body.component.html',
  styleUrls: ['./widget-body.component.scss'],
})
export class WidgetBodyComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
