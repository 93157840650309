import { OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Autowired } from '../../decorators/decorators';
import { DialogService } from '../../services/dialog.service';

export interface Breadcrumb {
  link?: string;
  title: string;
}

export class AbstractPageComponent implements OnDestroy {
  pageTitle: string;
  breadcrumb: Breadcrumb[];

  @Autowired()
  protected dialogService: DialogService;

  @Autowired()
  translateService: TranslateService;

  protected onDestroy$: Subject<void> = new Subject<void>();

  constructor(pageTitle: string, breadcrumb: Breadcrumb[]) {
    this.pageTitle = pageTitle;
    this.breadcrumb = breadcrumb;
  }

  async canDeactivate(): Promise<boolean> {
    if (this.hasChanged()) {
      const questionMessage = await this.translateService
        .get('Common.Alert.LeaveSite.question')
        .toPromise();
      const confirmMessage = await this.translateService
        .get('Common.Alert.LeaveSite.confirm')
        .toPromise();
      return await this.dialogService.openConfirm(questionMessage, confirmMessage);
    } else {
      return true;
    }
  }

  hasChanged(): boolean {
    return true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
