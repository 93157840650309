//
// selector Defines a selector to filter the results. Required.
//   $lt Match fields “less than” this one.
//   $gt Match fields “greater than” this one.
//   $lte Match fields “less than or equal to” this one.
//   $gte Match fields “greater than or equal to” this one.
//   $eq Match fields equal to this one.
//   $ne Match fields not equal to this one.
//   $exists True if the field should exist, false otherwise.
//   $type One of: “null”, “boolean”, “number”, “string”, “array”, or “object”.
//   $in The document field must exist in the list provided.
//   $and Matches if all the selectors in the array match.
//   $nin The document field must not exist in the list provided.
//   $all Matches an array value if it contains all the elements of the argument array.
//   $size Special condition to match the length of an array field in a document.
//   $or Matches if any of the selectors in the array match. All selectors must use the same index.
//   $nor Matches if none of the selectors in the array match.
//   $not Matches if the given selector does not match.
//   $mod Matches documents where (field % Divisor == Remainder) is true, and only when the document field is an integer.
//   $regex A regular expression pattern to match against the document field.
//   $elemMatch Matches all documents that contain an array field with at least one element that matches all the specified query criteria.
//

export class Selector {
  $options?: {
    topId?: string;
    limit?: number;
    pageIndex?: number;
    sortBy?: string;
    sortOrder?: 'ASC' | 'DESC';
    sortType?: 'String' | 'date';
  };
  $embeddedFields?: Array<string>;
  $filterByEmployeeId?: string;
  $lt?: string | number | Date; // Match fields “less than” this one.
  $gt?: string | number | Date; // Match fields “greater than” this one.
  $lte?: string | number | Date; //  Match fields “less than or equal to” this one.
  $gte?: string | number | Date; // Match fields “greater than or equal to” this one.
  $eq?: string | string | number | Date; // Match fields equal to this one.
  $like?: string; // Match field equal to %value%.
  $ne?: string | number | Date; // Match fields not equal to this one.
  // $exists?: boolean; // True if the field should exist, false otherwise.
  $and?: Array<any>; //  Matches if all the selectors in the array match.
  $or?: Array<any>; // Matches if any of the selectors in the array match. All selectors must use the same index.
  // $nor?: Array<any>; // Matches if none of the selectors in the array match.
  // $not?: Array<any>; // Matches if the given selector does not match.
  // $type One of: “null”, “boolean”, “number”, “string”, “array”, or “object”.
  $in?: Array<string | number | Date>; // The document field must exist in the list provided.
  $nin?: Array<any>; // The document field must not exist in the list provided.
  // $all: Array<any>; // Matches an array value if it contains all the elements of the argument array.
  // $size Special condition to match the length of an array field in a document.

  // $mod Matches documents where (field % Divisor == Remainder) is true, and only when the document field is an integer.
  // $regex A regular expression pattern to match against the document field.
  // $elemMatch Matches all documents that contain an array field with at least one element that matches all the specified query criteria.

  constructor(selector: {
    $lt?: string | number | Date; // Match fields “less than” this one.
    $gt?: string | number | Date; // Match fields “greater than” this one.
    $lte?: string | number | Date; //  Match fields “less than or equal to” this one.
    $gte?: string | number | Date; // Match fields “greater than or equal to” this one.
    $eq?: string | string | number | Date; // Match fields equal to this one.
    $like?: string; // Match field equal to %value%.
    $ne?: string | number | Date; // Match fields not equal to this one.
    // $exists?: boolean; // True if the field should exist, false otherwise.
    $and?: Array<any>; //  Matches if all the selectors in the array match.
    $or?: Array<any>; // Matches if any of the selectors in the array match. All selectors must use the same index.
    // $nor?: Array<any>; // Matches if none of the selectors in the array match.
    // $not?: Array<any>; // Matches if the given selector does not match.
    // $type One of: “null”, “boolean”, “number”, “string”, “array”, or “object”.
    $in?: Array<string | number | Date>; // The document field must exist in the list provided.
    $nin?: Array<any>; // The document field must not exist in the list provided.
    // $all: Array<any>; // Matches an array value if it contains all the elements of the argument array.
    // $size Special condition to match the length of an array field in a document.

    // $mod Matches documents where (field % Divisor == Remainder) is true, and only when the document field is an integer.
    // $regex A regular expression pattern to match against the document field.
    // $elemMatch Matches all documents that contain an array field with at least one element that matches all the specified query criteria.
  }) {
    Object.entries(selector).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}
