import { Entity, Field } from '../decorators/decorators';
import { ExaminationRequest } from './examinationRequest';
import { Medication } from './medication';
import { Model } from './model';
import { TreatmentAssignedService } from './treatment-assigned-service';

@Entity()
export class Prescription extends Model {
  @Field()
  diagnosis?: string;

  @Field({ itemType: Medication })
  medications?: Medication[];

  @Field({ itemType: Medication })
  externalMedications?: Medication[];

  @Field({ itemType: ExaminationRequest })
  examinationRequests?: ExaminationRequest[];

  @Field({ itemType: TreatmentAssignedService })
  treatmentServices?: TreatmentAssignedService[];

  @Field()
  comment?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
