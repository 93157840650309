import { Component } from '@angular/core';

let instances = 0;

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'counter',
  template: '<h1>{{this.id}}</h1>',
})
// tslint:disable-next-line:component-class-suffix
export class Counter {
  id: number;

  constructor() {
    this.id = ++instances;
  }
}
