import 'reflect-metadata';
import { AuthService } from '../../auth';
import { IcInjectorService } from '../services/ic-injector.service';
import { UtilityService } from '../services/utility.service';

const DESIGN_META_DATA = UtilityService.DESIGN_META_DATA;

export class EntityContainer {
  private static entities: Array<new (...args: any[]) => void> = [];

  public static addEntity(entity: new (...args: any[]) => void) {
    this.entities.push(entity);
  }

  public static getEntities(): Array<new (...args: any[]) => void> {
    return this.entities;
  }

  public static findEntity(name: string): new (...args: any[]) => void {
    return this.entities.find((item) => item.name === name);
  }
}

export function Entity() {
  // tslint:disable-next-line:ban-types
  return (target: new (...args: any[]) => void /*...args: any[]*/): void => {
    const clazz = target;
    EntityContainer.addEntity(clazz);
  };
}

// tslint:disable-next-line:ban-types
export function Field(map?: { mapping?: string; itemType?: new (...args: any[]) => void }) {
  return (...args: any[]): void => {
    const clazz = args[0];
    const property = args[1];
    const type = Reflect.getMetadata('design:type', clazz, property);
    const mapping = !!map && !!map.mapping ? map.mapping : property;
    const itemType = !!map && !!map.itemType ? map.itemType : undefined;
    if (type.name === 'Array' && !itemType) {
      throw new Error(
        `itemType is required in @Field of Array property "${clazz.constructor.name}.${property}"`
      );
    }
    const properties = [
      ...(Reflect.getOwnMetadata(DESIGN_META_DATA.ENTITY, clazz.constructor) || []),
      {
        name: property,
        type,
        itemType,
        mapping,
      },
    ];
    Reflect.defineMetadata(DESIGN_META_DATA.ENTITY, properties, clazz.constructor);
  };
}

export function Autowired() {
  return (target: any, property: string) => {
    const type: any = Reflect.getMetadata('design:type', target, property);
    const getter = () => {
      const instance = IcInjectorService.getInstance(type);
      if (!instance) {
        throw Error(
          `${target.constructor.name}.${property} is instance of ${type.name} which is not defined of @Injectable`
        );
      } else {
        return instance;
      }
    };
    // property setter
    const setter = (newVal: any) => {
      throw Error('Can not change config value');
    };
    // Delete property.
    if (delete target[property]) {
      // Create new property with getter and setter
      Object.defineProperty(target, property, {
        get: getter,
        set: setter,
        enumerable: true,
        configurable: true,
      });
    }
  };
}
