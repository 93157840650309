import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class ExaminationRequest extends Model {
  @Field()
  type?: string;

  @Field({ itemType: String })
  names?: Array<string>;

  @Field()
  comment?: string;

  @Field()
  attachment?: string;
  source: any;

  constructor(obj?: object) {
    super(obj);
  }
}
