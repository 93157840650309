import { CurrencyPipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ic-currency-input',
  templateUrl: './currency-input.component.html',
  styleUrls: ['./currency-input.component.scss'],
})
export class CurrencyInputComponent implements OnInit, OnChanges {
  currencyInputFormGroup: FormGroup;
  @Input() styleClass: string;
  @Input() placeholder: string;
  @Input() disableCondition: boolean;
  @Input() inputAmount: number;
  @Input() allowNegative = false;
  @Output() changeEvent = new EventEmitter<number>();

  constructor(private fb: FormBuilder, private currencyPipe: CurrencyPipe) {
    this.currencyInputFormGroup = this.fb.group({
      amountControl: ['', Validators.required],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputAmount && changes.inputAmount.currentValue) {
      this.currencyInputFormGroup.get('amountControl').patchValue(changes.inputAmount.currentValue);
    }
    if (changes.disableCondition && changes.disableCondition.currentValue) {
      this.currencyInputFormGroup.disable();
      this.currencyInputFormGroup
        .get('amountControl')
        .setValue(
          this.currencyPipe.transform(changes.inputAmount.currentValue.toString(), 'VND', '')
        );
    } else {
      this.currencyInputFormGroup.enable();
    }
  }

  ngOnInit(): void {
    const amountCtrl: AbstractControl = this.currencyInputFormGroup.get('amountControl');
    if (amountCtrl && amountCtrl.value) {
      amountCtrl.setValue(this.currencyPipe.transform(this.inputAmount.toString(), 'VND', ''));
    }
    this.currencyInputFormGroup.valueChanges.subscribe((form) => {
      if (form.amountControl || form.amountControl === '') {
        form.amountControl = form.amountControl.toString();
        if (this.allowNegative) {
          this.handleNegativeAmount(form);
        } else {
          this.handlePositiveAmount(form);
        }
      }
    });
  }

  onInputChanges(): string {
    const inputValue = this.currencyInputFormGroup.get('amountControl').value;
    if (inputValue !== undefined && inputValue !== null) {
      return this.allowNegative
        ? inputValue.replace(/[^0-9.-]/g, '').replace(/(\..*?)\..*/g, '$1')
        : inputValue.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    }
    return '';
  }

  private handlePositiveAmount(form): void {
    this.currencyInputFormGroup.patchValue(
      {
        amountControl: this.currencyPipe.transform(
          form.amountControl.replace(/\D/g, '').replace(/^0+/, ''),
          'VND',
          ''
        ),
      },
      { emitEvent: false }
    );
    this.changeEvent.emit(Number(form.amountControl.replace(/\D/g, '').replace(/^0+/, '')));
  }

  private handleNegativeAmount(form): void {
    const isNegative: boolean = form.amountControl.charAt(0) === '-';
    const formattedValue: string = this.currencyPipe.transform(
      form.amountControl.replace(/\D/g, '').replace(/^0+/, ''),
      'VND',
      ''
    );
    this.currencyInputFormGroup.patchValue(
      { amountControl: isNegative ? '-' + (formattedValue || '') : formattedValue },
      { emitEvent: false }
    );
    if (formattedValue) {
      let formatEmittedNumber: string = formattedValue.replace(/[^0-9.-]/g, '').replace(/^0+/, '');
      formatEmittedNumber = isNegative ? '-' + formatEmittedNumber : formatEmittedNumber;
      this.changeEvent.emit(Number(formatEmittedNumber));
    } else {
      this.changeEvent.emit(0);
    }
  }
}
