import { Injectable } from '@angular/core';
import { AbstractApi, CustomerProfile } from '../../ic-core/';

@Injectable({
  providedIn: 'root',
})
export class CustomerProfileApi extends AbstractApi<CustomerProfile> {
  protected uri: string;
  constructor() {
    super(CustomerProfile);
  }
}
