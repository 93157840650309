import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

export enum MedicationType {
  PASTE = 'PASTE',
  MEDICINE = 'MEDICINE',
}

@Entity()
export class Medication extends Model {
  @Field()
  name?: string;

  @Field()
  medicationType?: MedicationType;

  @Field()
  code?: string;

  @Field()
  frequency?: number;

  @Field()
  dose?: number;

  @Field()
  quantity?: number;

  @Field()
  orderQuantity?: number;

  @Field()
  totalAmount?: number;

  @Field()
  comment?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
