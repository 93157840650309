import { Injectable } from '@angular/core';
import { AbstractDao, CustomerProfile } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class CustomerProfileDao extends AbstractDao<CustomerProfile> {
  constructor() {
    super(CustomerProfile);
  }

  protected postInit() {}
}
