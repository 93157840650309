import { Injectable } from '@angular/core';
import { Route, Router } from '@angular/router';
import 'reflect-metadata';

const DESIGN_META_DATA = {
  APP: 'design:meta:data:key:app',
  CONFIG: 'design:meta:data:key:config',
  POST_INIT: 'design:meta:data:key:post.init',
  AUTOWIRED: 'design:meta:data:key:autowired',
  SERVICE: 'design:meta:data:key:service',
  SERVICE_MAPPING: 'design:meta:data:key:service:mapping',
  PATH: 'design:meta:data:key:path',
  METHOD: 'design:meta:data:key:method',
  PARAMETER: 'design:meta:data:key:parameter',
  PATH_PARAMETER: 'design:meta:data:key:path:parameter',
  REQUEST: 'design:meta:data:key:request',
  RESPONSE: 'design:meta:data:key:response',
  QUERY: 'design:meta:data:key:query',
  QUERY_PARAMETER: 'design:meta:data:key:query:parameter',
  BODY: 'design:meta:data:key:body',
  BODY_PARAMETER: 'design:meta:data:key:body:parameter',
  HEADERS: 'design:meta:data:key:headers',
  HEADERS_PARAMETER: 'design:meta:data:key:headers:parameter',
  ENTITY: 'design:meta:data:key:entity',
};

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  static DESIGN_META_DATA = DESIGN_META_DATA;

  static delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static getViewPortHeight(): number {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }

  static getViewPortWidth(): number {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  }

  static getDeeplyProperty(obj: object, property: string) {
    if (property.includes('.')) {
      //// EX: propertyPath === 'contact.email'
      try {
        return property.split('.').reduce((o, i) => o[i], obj);
      } catch (err) {
        return undefined;
      }
    }
    return obj[property];
  }

  static setDeeplyProperty(obj: object, property: string, value: any) {
    if (property === './') {
      obj = Object.assign(obj, value);
    } else if (!property.includes('.')) {
      //// EX: propertyPath === 'contact.email'
      obj[property] = value;
    } else {
      const propertyItem = property.split('.');
      const first = propertyItem.shift();
      obj[first] = obj[first] || {};
      const propertyObject = obj[first];
      UtilityService.setDeeplyProperty(propertyObject, propertyItem.join('.'), value);
    }
  }

  static findSuper(child: new (...args: any[]) => void): new (...args: any[]) => void {
    // tslint:disable-next-line:variable-name
    const _super: new (...args: any[]) => void = Object.getPrototypeOf(child.prototype).constructor;
    return _super;
  }

  static findDeclaredProperties(
    clazz: new (...args: any[]) => void
  ): Array<{
    name: string;
    type: new (...args: any[]) => void;
    mapping: string;
    itemType: new (...args: any[]) => void;
  }> {
    // tslint:disable-next-line:variable-name
    let _clazz = clazz;
    let properties: Array<{
      name: string;
      type: new (...args: any[]) => void;
      mapping: string;
      itemType: new (...args: any[]) => void;
    }> = [];
    while (_clazz.name && _clazz.name !== 'Object') {
      const p = (Reflect.getOwnMetadata(DESIGN_META_DATA.ENTITY, _clazz) || []).filter(
        (item) => !properties.some((i) => i.name === item.name) // remove item is existed in child class
      );
      properties = [...p, ...properties];
      _clazz = this.findSuper(_clazz);
    }
    return properties;
  }

  static collectSchema(
    clazz: new (...args: any[]) => void
  ): Array<{ name: string; properties: Array<{ name: string; type: string }> }> {
    // tslint:disable-next-line:variable-name
    const _clazz: new (...args: any[]) => void = clazz;
    let schema: Array<{ name: string; properties: Array<{ name: string; type: string }> }> = [];
    const declaredProperties: Array<{
      name: string;
      type: new (...args: any[]) => void;
    }> = this.findDeclaredProperties(_clazz);
    let properties: Array<{ name: string; type: string }>;
    if (declaredProperties.length > 0) {
      properties = declaredProperties.map((property) => {
        const childSchema = this.collectSchema(property.type);
        if (childSchema.length > 0) {
          schema.push(...childSchema);
        }
        return { name: property.name, type: property.type.name };
      });
      schema.push({ name: clazz.name, properties });
      schema = schema.filter((item, index, target) => target.indexOf(item) === index);
    }
    return schema;
  }

  static isNotEmptyObject(obj) {
    let result = false;
    if (obj !== null && obj !== undefined) {
      if (['string', 'number', 'boolean'].includes(typeof obj)) {
        result = true;
      } else {
        if (typeof obj === 'object') {
          if (
            obj instanceof String ||
            obj instanceof Date ||
            obj instanceof Number ||
            obj instanceof Boolean
          ) {
            result = true;
          } else if (Object.keys(obj).length > 0) {
            Object.keys(obj).forEach((property) => {
              if (!result) {
                result = this.isNotEmptyObject(obj[property]);
              }
            });
          }
        }
      }
    }
    return result;
  }

  static parseDataToModel(data: object, targetType: new (...args: any[]) => void): any {
    const targetInstance = new targetType();
    const properties: Array<{
      name: string;
      type: new (...args: any[]) => void;
      mapping: string;
      itemType: new (...args: any[]) => void;
    }> = UtilityService.findDeclaredProperties(targetType);
    if (!!data) {
      properties.forEach(({ name, type, mapping, itemType }) => {
        let value = mapping === './' ? data : UtilityService.getDeeplyProperty(data, mapping);
        if (!!value && !(value.constructor.name === type.name)) {
          value = new type(value);
        } else {
          if (type.name === 'Array') {
            value = UtilityService.parseDataToArrayModel(value as Array<object>, itemType);
          }
        }
        targetInstance[name] = value;
      });
    }
    return targetInstance;
  }

  static setDataToInstance(data: object, targetInstance: object): any {
    const targetType = targetInstance.constructor;
    const properties: Array<{
      name: string;
      type: new (...args: any[]) => object;
      mapping: string;
      itemType: new (...args: any[]) => object;
    }> =
      // @ts-ignore
      UtilityService.findDeclaredProperties(targetType);
    if (!!data) {
      properties.forEach(({ name, type, mapping, itemType }) => {
        let value = mapping === './' ? data : UtilityService.getDeeplyProperty(data, mapping);
        if (!!value) {
          if (UtilityService.isEntity(type)) {
            const subInstance = new type({});
            UtilityService.setDataToInstance(value, subInstance);
            value = subInstance;
          } else if (type.name === 'Date') {
            value = new type(value);
          } else {
            if (
              type.name === 'Array' &&
              UtilityService.isEntity(itemType) &&
              Array.isArray(value)
            ) {
              value = (value as Array<object>).map((val) => {
                const subInstance = new itemType({});
                UtilityService.setDataToInstance(val, subInstance);
                return subInstance;
              });
            }
          }
        }
        targetInstance[name] = value;
      });
    }
  }

  static parseDataToArrayModel(
    array: Array<object>,
    itemType: new (...args: any[]) => void
  ): any[] {
    if (!array || !Array.isArray(array)) {
      return [];
    } else {
      return array.map((item) => UtilityService.parseDataToModel(item, itemType));
    }
  }

  static isEntity(clazz: new (...args: any[]) => void): boolean {
    if (clazz.name === 'Object') {
      return false;
    } else if (!!Reflect.getOwnMetadata(DESIGN_META_DATA.ENTITY, clazz)) {
      return true;
    } else {
      return this.isEntity(this.findSuper(clazz));
    }
  }

  static isTabletDevice() {
    return /Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
  }

  static findParentRouteConfig(router: Router): Route {
    const urlItems = router.url.split('/');
    urlItems.shift();

    const modulePath = urlItems.shift();
    const moduleRoute = router.config.find((route) => route.path === modulePath);
    if (!moduleRoute || urlItems.length === 0) {
      return null;
    } else {
      if (urlItems.length === 1) {
        return moduleRoute;
      } else {
        let route;
        while (urlItems.length > 1 && !route) {
          urlItems.pop();
          route = this.findChildRouteConfigFromPath(moduleRoute, urlItems);
        }
        return !!route ? route : moduleRoute;
      }
    }
  }

  static findCurrentRouteConfig(router: Router): Route {
    const urlItems = router.url.split('/');
    urlItems.shift();

    const modulePath = urlItems.shift();
    const moduleRoute = router.config.find((route) => route.path === modulePath);
    if (!moduleRoute) {
      return null;
    } else if (urlItems.length === 0) {
      return moduleRoute;
    }
    return this.findChildRouteConfigFromPath(moduleRoute, urlItems);
  }

  private static findChildRouteConfigFromPath(route: Route, pathItems: string[]): Route {
    const loadedConfig = '_loadedConfig';
    const directChildren = route.children || route[loadedConfig].routes;

    let mappedChildren = directChildren.filter((routeChild) => {
      const paths = routeChild.path.split('/');
      if (paths.length > pathItems.length || paths.length === 0) {
        return false;
      }
      const filterPaths = paths.filter(
        (path, index) => path.startsWith(':') || path === pathItems[index]
      );
      return filterPaths.length === paths.length;
    });

    if (mappedChildren.length === 0) {
      mappedChildren = directChildren.find((routeChild) => routeChild.path === '');
      if (mappedChildren) {
        return this.findChildRouteConfigFromPath(mappedChildren, pathItems);
      } else {
        return null;
      }
    } else if (mappedChildren.length > 1) {
      mappedChildren = mappedChildren.reduce((selectedChild, indexChild) => {
        const selectedMatchedPoint = selectedChild.path
          .split('/')
          .reduce((sumMatchPoint: number, indexPathItem: string, index: number) => {
            let indexPoint = 0;
            if (indexPathItem === pathItems[index] || indexPathItem.startsWith(':')) {
              indexPoint = 1;
            }
            return sumMatchPoint + indexPoint;
          }, 0);

        const indexMatchedPoint = indexChild.path
          .split('/')
          .reduce((sumMatchPoint: number, indexPathItem: string, index: number) => {
            let indexPoint = 0;
            if (indexPathItem === pathItems[index] || indexPathItem.startsWith(':')) {
              indexPoint = 1;
            }
            return sumMatchPoint + indexPoint;
          }, 0);

        if (selectedMatchedPoint === indexMatchedPoint) {
          return selectedChild.path.split('/:').length + selectedChild.path.startsWith(':') <
            indexChild.path.split('/:').length + indexChild.path.startsWith(':')
            ? selectedChild
            : indexChild;
        } else {
          return selectedMatchedPoint < indexMatchedPoint ? indexChild : selectedChild;
        }
      });
    } else {
      mappedChildren = mappedChildren[0];
    }
    const childPaths = pathItems.slice(mappedChildren.path.split('/').length, pathItems.length);
    return !!childPaths.length
      ? this.findChildRouteConfigFromPath(mappedChildren, childPaths)
      : mappedChildren;
  }
}
