import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import * as jQuery from 'jquery';
import moment from 'moment';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppContainer } from './app.container';
import { MaterialModule } from './material-module';
import { BranchSelectionsComponent } from './pages/branch-selections/branch-selections.component';
import { HomeComponent } from './pages/home/home.component';
import { ManageDeployComponent } from './pages/manage-deploy/manage-deploy.component';
import { TestComponentsComponent } from './pages/test-components/test-components.component';
import { IcInjectorService, InitializerProvider } from './sub-modules/ic-core';
import { IcCoreModule } from './sub-modules/ic-core/ic-core.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

export function initializerProviderFactory(initializer: InitializerProvider) {
  return async () => await initializer.load();
}

jQuery(document).on('focusout', 'input', (event) => {
  const val = jQuery(event.target).val();
  if (typeof val === 'string' || val instanceof String) {
    jQuery(event.target).val(val.trim());
  }
  jQuery(event.target).val(jQuery(event.target).val());
});

Date.prototype.toISOString = function() {
  return moment(this).format();
};
Date.prototype.toString = function() {
  return moment(this).format();
};

@NgModule({
  declarations: [
    AppContainer,
    HomeComponent,
    TestComponentsComponent,
    ManageDeployComponent,
    BranchSelectionsComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,

    AppRoutingModule,

    MaterialModule,

    NgxMatSelectSearchModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IcCoreModule.forRoot(),
    AmplifyAngularModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    InitializerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initializerProviderFactory,
      deps: [InitializerProvider],
      multi: true,
    },
    AmplifyService,
    { provide: MAT_DATE_LOCALE, useValue: 'vi' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    // {provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher}
  ],
  bootstrap: [AppContainer],
})
export class AppModule {
  constructor(injector: Injector) {
    IcInjectorService.setAppInjector(injector);
  }
}
