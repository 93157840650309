import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ic-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() title: string;
  @Input() breadcrumb: Array<{ link?: string; title: string }>;

  constructor() {}

  ngOnInit() {}
}
