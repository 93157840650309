import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../../material-module';
import { AlertDialog } from './components/alert-dialog/alert.dialog';
import { AutocompleteObjectComponent } from './components/autocomplete-object/autocomplete-object.component';
import { CameraStreamComponent } from './components/camera-stream/camera-stream.component';
import { ChipsAutocompleteObjectComponent } from './components/chips-autocomplete-object/chips-autocomplete-object.component';
import { ChipsAutocompleteComponent } from './components/chips-autocomplete/chips-autocomplete.component';
import { ChoosePhotoComponent } from './components/choose-photo/choose-photo.component';
import { ClockPickerComponent } from './components/clock-picker/clock-picker.component';
import { ConfirmDialog } from './components/confirm-dialog/confirm.dialog';
import { Counter } from './components/counter/counter.component';
import { CropPhotoDialog } from './components/crop-photo-dialog/crop-photo.dialog';
import { CropPhotoComponent } from './components/crop-photo/crop-photo.component';
import { CurrencyInputComponent } from './components/currency-input/currency-input.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { DialogContainerComponent } from './components/dialog-container/dialog-container.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageComparisonComponent } from './components/image-comparison/image-comparison.component';
import { InputDialog } from './components/input-dialog/input-dialog';
import { NavComponent } from './components/nav/nav.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { SignCanvasWidgetComponent } from './components/sign-canvas-widget/sign-canvas-widget.component';
import { SignCanvasComponent } from './components/sign-canvas/sign-canvas.component';
import { SprintPageComponent } from './components/sprint-page/sprint-page.component';
import { TakePhotoDialog } from './components/take-photo-dialog/take-photo.dialog';
import { TakePhotoComponent } from './components/take-photo/take-photo.component';
import { TimeoutDialogComponent } from './components/timeout-dialog/timeout-dialog.component';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { UsersSelectComponent } from './components/users-select/users-select.component';
import { WidgetBodyComponent } from './components/widget-body/widget-body.component';
import { WidgetFooterComponent } from './components/widget-footer/widget-footer.component';
import { WidgetHeaderExecutionComponent } from './components/widget-header-execution/widget-header-execution.component';
import { WidgetHeaderTitleComponent } from './components/widget-header-title/widget-header-title.component';
import { WidgetHeaderComponent } from './components/widget-header/widget-header.component';
import { WidgetComponent } from './components/widget/widget.component';
import { Wrapper } from './components/wrapper/wrapper.component';
import { DataTableColumnDirective } from './directives/data-table-column.directive';
import { PermitsDirective } from './directives/permits.directive';
import { TemplateDisplayForDirective } from './directives/template-display-for.directive';
import { IcInjectorService } from './services/ic-injector.service';
import { PageLoaderService } from './services/page-loader.service';
import { SocketEventService } from './services/socket-event.service';
import { UtilityService } from './services/utility.service';

const components = [
  HeaderComponent,
  NavComponent,
  ChipsAutocompleteComponent,
  ChipsAutocompleteObjectComponent,
  PageLoaderComponent,
  ChoosePhotoComponent,
  TakePhotoComponent,
  ClockPickerComponent,
  SignCanvasComponent,
  SignCanvasWidgetComponent,
  SprintPageComponent,
  ImageComparisonComponent,
  //
  WidgetComponent,
  WidgetHeaderComponent,
  WidgetBodyComponent,
  WidgetFooterComponent,
  WidgetHeaderTitleComponent,
  WidgetHeaderExecutionComponent,
  Counter,
  Wrapper,
  DataTableComponent,
];

const directives = [DataTableColumnDirective, TemplateDisplayForDirective, PermitsDirective];

const entryComponents = [
  InputDialog,
  ConfirmDialog,
  AlertDialog,
  TakePhotoDialog,
  CropPhotoDialog,
  CameraStreamComponent,
  CropPhotoComponent,
  TimeoutDialogComponent,
];
export const providers = [
  PageLoaderService,
  UtilityService,
  IcInjectorService,
  SocketEventService,
  // AbstractDao
];

const modules = [
  CommonModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
  HttpClientModule,
  TranslateModule,
  MaterialModule,
];

@NgModule({
  declarations: [
    ...components,
    ...entryComponents,
    ...directives,
    UserSelectComponent,
    UsersSelectComponent,
    DialogContainerComponent,
    AutocompleteObjectComponent,
    CurrencyInputComponent,
    TimeoutDialogComponent,
  ],
  entryComponents,
  imports: [...modules],
  exports: [
    ...components,
    ...entryComponents,
    ...modules,
    WidgetComponent,
    WidgetHeaderComponent,
    Wrapper,
    ...directives,
    UserSelectComponent,
    UsersSelectComponent,
    DialogContainerComponent,
    AutocompleteObjectComponent,
    CurrencyInputComponent,
  ],
})
export class IcCoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: IcCoreModule,
      providers: [...providers],
    };
  }
}
