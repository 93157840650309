import { Injectable } from '@angular/core';
import { Autowired, Treatment } from '../../ic-core';
import { AbstractDeepRepository } from '../../ic-core/repository/abstract.deep.repository';
import { TreatmentApi } from '../api/treatment.api';
import { TreatmentDao } from '../dao/treatment.dao';

@Injectable({
  providedIn: 'root',
})
export class TreatmentRepository extends AbstractDeepRepository<Treatment> {
  @Autowired()
  protected dao: TreatmentDao;
  @Autowired()
  protected api: TreatmentApi;
  constructor() {
    super(Treatment);
  }
}
