import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'ic-crop-photo-dialog',
  templateUrl: './crop-photo.dialog.html',
  styleUrls: ['./crop-photo.dialog.scss'],
})
// tslint:disable-next-line:component-class-suffix
export class CropPhotoDialog implements OnInit {
  dataUrl: string;

  constructor(
    public dialogRef: MatDialogRef<CropPhotoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {}

  ngOnInit() {
    // @ts-ignore
    this.dataUrl = this.data.dataUrl;
  }

  cropPhoto(event) {
    this.dialogRef.close(event);
  }
}
