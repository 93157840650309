import { Component } from '@angular/core';
import { AbstractPageComponent, Breadcrumb } from '../../sub-modules/ic-core';

const title = 'Manage Deploy';
const breadcrumb: Breadcrumb[] = [{ title: 'Manage Deploy' }];

@Component({
  selector: 'ic-manage-deploy',
  templateUrl: './manage-deploy.component.html',
  styleUrls: ['./manage-deploy.component.scss'],
})
export class ManageDeployComponent extends AbstractPageComponent {
  data = {
    client: {
      clientName: '',
      environmentName: '',
      region: '',
      calendar: '',
      bucketState: '',
      accountId: '',
    },
    buildVersion: {
      frontEnd: '',
      backEnd: '',
      buildBucket: '',
    },
    system: {
      enableCognito: '',
      cognitoClientId: '',
      cognitoPoolId: '',
      lambdaMemorySize: '',
      payrollScheduler: '',
    },
  };

  constructor() {
    super(title, breadcrumb);
  }

  async save(): Promise<void> {
    const answer = await this.dialogService.openConfirm('', 'Do you want to update config ?');
    if (answer) {
      alert(JSON.stringify(this.data));
    }
  }
}
