import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService } from '../../services/dialog.service';

const base64ToBlob = (dataURI, contentType) => {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: contentType });
};
const blobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

@Component({
  selector: 'ic-choose-photo',
  templateUrl: './choose-photo.component.html',
  styleUrls: ['./choose-photo.component.scss'],
})
export class ChoosePhotoComponent implements OnInit {
  @Input()
  icon: string;
  @Input()
  croppable: boolean | string;
  @Input()
  multiple: boolean | string;
  @Input()
  source:
    | File
    | Blob
    | string
    | ArrayBuffer
    | { file: File | Blob; dataUrl: string; base64String: string | ArrayBuffer };
  @Output()
  sourceChange = new EventEmitter<
    | File
    | Blob
    | string
    | ArrayBuffer
    | { file: File | Blob; dataUrl: string; base64String: string | ArrayBuffer }
    | Array<{ file: File | Blob; dataUrl: string; base64String: string | ArrayBuffer }>
  >();
  @Output()
  chose = new EventEmitter<
    | { file: File | Blob; dataUrl: string; base64String: string | ArrayBuffer }
    | Array<{ file: File | Blob; dataUrl: string; base64String: string | ArrayBuffer }>
  >();

  @ViewChild('input', { static: false }) input: ElementRef;

  constructor(private dialogService: DialogService) {}

  ngOnInit() {
    this.croppable = ['true', 'TRUE', true].includes(this.croppable);
    this.multiple = ['true', 'TRUE', true].includes(this.multiple);
    // this.source
  }

  public chooseFile() {
    this.input.nativeElement.click();
  }

  async readPhoto(event) {
    const input = event.target;
    const url = input.value;
    const ext = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
    if (
      input.files &&
      input.files[0] &&
      (ext === 'gif' || ext === 'png' || ext === 'jpeg' || ext === 'jpg')
    ) {
      const files = input.files;
      const photoData: Array<{
        file: File | Blob;
        dataUrl: string;
        base64String: string | ArrayBuffer;
      }> = [];
      for (const file of files) {
        const data: {
          file: File | Blob;
          dataUrl: string;
          base64String: string | ArrayBuffer;
        } = await this.buildPhotoData(file);
        photoData.push(data);
      }
      this.sourceChange.emit(this.multiple ? photoData : photoData[0]);
      this.chose.emit(this.multiple ? photoData : photoData[0]);
    }
  }

  async buildPhotoData(
    file: File
  ): Promise<{ file: File | Blob; dataUrl: string; base64String: string | ArrayBuffer }> {
    const reader = new FileReader();
    const dataUrl = URL.createObjectURL(file);
    // const contentType = file.type;
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      reader.onload = async (ev) => {
        // @ts-ignore
        const base64String = ev.target.result;
        if (this.croppable) {
          const croppedData = await this.cropPhoto({ file, dataUrl, base64String });
          resolve(croppedData);
        } else {
          resolve({ file, dataUrl, base64String });
        }
      };
    });
  }

  async cropPhoto(data: {
    file: File | Blob;
    dataUrl: string;
    base64String: string | ArrayBuffer;
  }): Promise<{ file: File | Blob; dataUrl: string; base64String: string | ArrayBuffer }> {
    const croppedData = await this.dialogService.openCropDialog(data.dataUrl);
    return croppedData;
  }
}
