import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BranchSelectionsComponent } from './pages/branch-selections/branch-selections.component';
import { HomeComponent } from './pages/home/home.component';
import { ManageDeployComponent } from './pages/manage-deploy/manage-deploy.component';
import { TestComponentsComponent } from './pages/test-components/test-components.component';
import { AuthService } from './sub-modules/auth';
import { AuthGuard } from './sub-modules/auth/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./sub-modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'branch',
    component: BranchSelectionsComponent,
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-deploy',
    component: ManageDeployComponent,
  },
  {
    path: 'test-components',
    component: TestComponentsComponent,
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('./sub-modules/accounts/accounts.module').then((m) => m.AccountsModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'billings',
    loadChildren: () =>
      import('./sub-modules/billings/billings.module').then((m) => m.BillingsModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'appointments',
    loadChildren: () =>
      import('./sub-modules/appointments/appointments.module').then((m) => m.AppointmentsModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'debts',
    loadChildren: () => import('./sub-modules/debts/debts.module').then((m) => m.DebtsModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    loadChildren: () => import('./sub-modules/reports/reports.module').then((m) => m.ReportsModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'storage',
    loadChildren: () => import('./sub-modules/storage/storage.module').then((m) => m.StorageModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'tasks',
    loadChildren: () => import('./sub-modules/tasks/tasks.module').then((m) => m.TasksModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'equipments',
    loadChildren: () =>
      import('./sub-modules/equipments/equipments.module').then((m) => m.EquipmentsModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'calendars',
    loadChildren: () =>
      import('./sub-modules/user-calendars/user-calendars.module').then(
        (m) => m.UserCalendarsModule
      ),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'assets',
    loadChildren: () => import('./sub-modules/assets/assets.module').then((m) => m.AssetsModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'policies-information',
    loadChildren: () =>
      import('./sub-modules/policies-information/policies-information.module').then(
        (m) => m.PoliciesInformationModule
      ),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  {
    path: 'authorization',
    loadChildren: () =>
      import('./sub-modules/authorization/authorization.module').then((m) => m.AuthorizationModule),
    resolve: { data: AuthService },
    canActivate: [AuthGuard],
  },
  // otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
