import { Injectable } from '@angular/core';
import { AbstractRepository, Role } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class RoleRepository extends AbstractRepository<Role> {
  constructor() {
    super(Role);
  }
}
