import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class EmbeddedProductItem extends Model {
  constructor(obj?: object) {
    super(obj);
  }

  @Field()
  productCode?: string;

  @Field()
  productName?: string;
}
