import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';
import { Prescription } from './prescription';

@Entity()
export class Attachment extends Model {
  @Field()
  name?: string;

  @Field()
  file?: Prescription;

  @Field()
  comment?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
