import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class SupplierDebtHistory extends Model {
  @Field()
  companyId?: string;

  @Field()
  recordedDate?: Date;

  @Field()
  creditAmount?: number;

  @Field()
  debitAmount?: number;

  @Field()
  comment?: string;

  @Field()
  employeeId?: string;

  @Field()
  targetId?: string;
  @Field()
  type?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
