import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import Amplify from 'aws-amplify';
import { IcConfig } from '..';

@Injectable({
  providedIn: 'root',
})
export class InitializerProvider {
  // @ts-ignore
  public CONFIG: IcConfig = {
    awsAmplify: {
      aws_project_region: 'ap-southeast-1',
      aws_cognito_region: 'ap-southeast-1',
      aws_user_pools_id: 'ap-southeast-1_PCSZEqJT9',
      aws_user_pools_web_client_id: '2m0njhvogorsv852erja78dtoc',
      oauth: {},
    },
  } as IcConfig;

  constructor(private http: HttpClient) {}

  load() {
    // Amplify.configure(this.CONFIG.awsAmplify);
    return new Promise((resolve, reject) => {
      this.http.get('/ic-config.json').subscribe((response) => {
        this.CONFIG = response as IcConfig;
        Amplify.configure(this.CONFIG.awsAmplify);
        resolve(true);
      });
    });
  }
}
