import { Entity, Field } from '../decorators/decorators';
import { Employee } from './employee';

@Entity()
export class PayslipCalculator extends Employee {
  @Field()
  message?: string;


  constructor(obj?: object) {
    super(obj);
  }
}
