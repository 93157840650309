import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class SaleTarget extends Model {
  @Field()
  targetAmount: number;
  @Field()
  commissionPercentage: number;
  @Field()
  bonusAmount: number;
  constructor(obj?: object) {
    super(obj);
  }
}
