import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from '../../sub-modules/accounts';
import { AuthService } from '../../sub-modules/auth';
import { Autowired, Branch, Employee } from '../../sub-modules/ic-core';
import { AccessPagePermits } from '../../sub-modules/ic-core/decorators/access-page-permits.decorator';
import { BranchService } from '../../sub-modules/policies-information/services/branch.service';


@Component({
  selector: 'ic-branch-selections',
  templateUrl: './branch-selections.component.html',
  styleUrls: ['./branch-selections.component.scss'],
})
@AccessPagePermits(['home.page.access'])
export class BranchSelectionsComponent implements OnInit {
  loggedInEmployee: Employee;
  branches: Branch[];

  @Autowired()
  authService: AuthService;
  @Autowired()
  branchService: BranchService;
  @Autowired()
  accountService: AccountService;

  constructor(
    private router: Router,
  ) {}


  async ngOnInit(): Promise<void> {
    await this.loadBranch();
  }


  async loadBranch(): Promise<void> {
    this.loggedInEmployee = await this.authService.getLoggedInEmployee();
    this.branches = await this.branchService.searchBranches({}).toPromise();
    if (!this.branches || this.branches.length === 0) {
      await this.router.navigate(['/home']);
    }
  }

  isSelectedBranch(branch: Branch): boolean {
    return !!this.loggedInEmployee.branch && this.loggedInEmployee.branch.id === branch.id;
  }

  async selectBranch(selectedBranch: Branch): Promise<void> {
    if (!this.isSelectedBranch(selectedBranch)) {
      const branch = { id: selectedBranch.id} as Branch;
      this.loggedInEmployee.branch = branch;
      await this.accountService.updateEmployee({id: this.loggedInEmployee.id, branch} as Employee);
    }
    await this.router.navigate(['/home']);
  }
}
