import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';
import { OrderedItem } from './ordered-item';

@Entity()
export class ReportItem extends Model {
  @Field()
  date: Date;
  // tslint:disable-next-line:ban-types
  @Field() amount: Number;
  // tslint:disable-next-line:ban-types
  @Field() productId: String;
  // tslint:disable-next-line:ban-types
  @Field() inventoryLocation: String;
  @Field() payments: any;
  @Field() customerId: String;
  @Field() employeeId: String;
  @Field({ itemType: OrderedItem })
  orderedItems: Array<OrderedItem>;
  @Field() doctorId: String;

  constructor(obj?: object) {
    super(obj);
  }
}
