import { Injectable } from '@angular/core';
import { AbstractApi, PayslipCalculator } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class PayslipApi extends AbstractApi<PayslipCalculator> {
  protected uri: string;
  constructor() {
    super(PayslipCalculator);
  }
}
