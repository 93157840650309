import { Observable } from 'rxjs';
import { AbstractApi } from '../api/abstract.api';
import { AbstractDao } from '../dao/abstract.dao';
import { Autowired } from '../decorators/decorators';
import { IcConfig } from '../models/ic-config';
import { Model } from '../models/model';
import { InitializerProvider } from '../providers/initializer.provider';

// tslint:disable-next-line:no-shadowed-variable
export class AbstractRepository<
  T extends Model
  /*, D extends AbstractDao<T> | AbstractDao<T>, A extends AbstractApi<T> | AbstractApi<T>*/
> {
  protected dao: AbstractDao<T>;
  protected api: AbstractApi<T>;
  protected icConfig: IcConfig;
  @Autowired()
  private initializerProvider: InitializerProvider;

  constructor(clazzT: new (obj: object) => T) {
    this.icConfig = this.initializerProvider.CONFIG;
    if (!this.dao) {
      this.dao = new AbstractDao(clazzT);
    }
    if (!this.api) {
      this.api = new AbstractApi(clazzT);
    }
  }

  public async create(t: T): Promise<T> {
    // let customerID: CustomerID;
    try {
      if (['online', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.api.create(t);
      }
      if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.dao.create(t);
      }
    } catch (err) {
      throw err;
    }
    return t;
  }

  public async update(t: T) {
    if (['online', 'sync'].includes(this.icConfig.dataMode)) {
      await this.api.update(t);
    }
    if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
      await this.dao.update(t);
    }
  }

  public async replace(t: T) {
    if (['online', 'sync'].includes(this.icConfig.dataMode)) {
      await this.api.replace(t);
    }
    if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
      await this.dao.replace(t);
    }
  }

  public async delete(id) {
    if (['online', 'sync'].includes(this.icConfig.dataMode)) {
      await this.api.delete(id);
    }
    if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
      await this.dao.delete(id);
    }
  }

  public search(query: any): Observable<T[]> {
    // @ts-ignore
    const observable = new Observable<T[]>(async (observer) => {
      let result: T[];
      if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
        // result = await this.dao.search({
        //   fullName: {
        //     $regex: '.*' + query.textSearch + '.*'
        //   }
        // });

        result = await this.dao.search(query);
        observer.next(result);
      }
      if (['online', 'sync'].includes(this.icConfig.dataMode)) {
        result = await this.api.search(query);
        observer.next(result);
      }
      observer.complete();
    });
    return observable;
  }

  public get(id: string): Observable<T> {
    // @ts-ignore
    const observable = new Observable<T>(async (observer) => {
      let t: T;
      if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.dao.get(id);
        observer.next(t);
      }
      if (['online', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.api.get(id);
        observer.next(t);
      }
      observer.complete();
    });
    return observable;
  }

  public getQuery(query: any, id: string): Observable<T> {
    // @ts-ignore
    const observable = new Observable<T>(async (observer) => {
      let t: T;
      if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.dao.get(id);
        observer.next(t);
      }
      if (['online', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.api.getQuery(query, id);
        observer.next(t);
      }
      observer.complete();
    });
    return observable;
  }
}
