import { Entity, Field } from '../decorators/decorators';
import { Employee } from './employee';
import { Model } from './model';

@Entity()
export class CustomerProfile extends Model {
  @Field()
  customerId: string;
  @Field({ itemType: Object })
  anamnesis?: Array<{ name: string; type: string }>;
  @Field({ itemType: String })
  personalAnamnesis: Array<string>;
  @Field({ itemType: String })
  familyAnamnesis?: Array<string>;
  @Field({ itemType: String })
  interests?: Array<string>;
  @Field({ itemType: String })
  habits?: Array<string>;
  @Field({ itemType: Object })
  notes?: Array<{ type?: string; message?: string }>;
  @Field({ itemType: Object })
  signInfo?: { date: Date; image: string };
  @Field({ itemType: String })
  medicalHistories?: Array<{
    serviceType?: string;
    medicalType?: string;
    treatmentPlace?: string;
    treatmentDate?: string;
    physicianName?: string;
    medicationNote?: string;
    doctorName?: string;
  }>;
  @Field({ itemType: Employee })
  shared?: Array<Employee>; // [{id:'2112'},{id: '1545'}];

  /*
  "medical-histories": [
    {
      "id": "496de47dd2aa95db649683bc",
      "medical-type": "Diabete",
      "physician-name": "string",
      "treatment-date": {},
      "medication-note": "Paracetamol, C, Hazeline",
      "comment": "Please put your comment here"
    }
  ],
  "shared": [
    {
      "id": "496de47dd2aa95db649683bc",
      "employee-id": "496de47dd2aa95db649683bc",
      "comment": "Please put your comment here"
    }
   */
  @Field({ itemType: Object })
  examinationResults?: Array<{ source?: string; createDate?: Date }>;
  @Field()
  examinationNotes?: string;

  constructor(obj?: object) {
    super(obj);
    // this.personalAnamnesis = obj['personalAnamnesis'];
    // this.familyAnamnesis = obj['familyAnamnesis'];
  }

  fromRequestData(data: object): void {
    super.fromRequestData(data);
    if (Array.isArray(this.anamnesis)) {
      this.anamnesis = this.anamnesis;
      this.personalAnamnesis = this.anamnesis
        .filter((item) => item.type === 'personal')
        .map((item) => item.name);
      this.familyAnamnesis = this.anamnesis
        .filter((item) => item.type === 'family')
        .map((item) => item.name);
    }
  }

  toRequestData(): object {
    if (Array.isArray(this.personalAnamnesis)) {
      this.anamnesis = this.anamnesis || [];
      this.anamnesis = this.anamnesis.filter((item) => item.type !== 'personal');
      this.anamnesis = [
        ...this.anamnesis,
        ...this.personalAnamnesis.map((name) => Object.assign({ name, type: 'personal' })),
      ];
    }
    if (Array.isArray(this.familyAnamnesis)) {
      this.anamnesis = this.anamnesis || [];
      this.anamnesis = this.anamnesis.filter((item) => item.type !== 'family');
      this.anamnesis = [
        ...this.anamnesis,
        ...this.familyAnamnesis.map((name) => Object.assign({ name, type: 'family' })),
      ];
    }
    return super.toRequestData();
  }
}
