import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class UsageHistory extends Model {
  @Field()
  customerId?: string;

  @Field()
  serviceCode?: string;

  @Field()
  quantity?: number;

  oldQuantity?: number;

  @Field()
  productQuantity?: number;

  @Field()
  serviceProduct?: boolean;

  @Field()
  technicianId?: string;

  @Field()
  usageStatus?: string;

  @Field()
  usageComment?: string;

  constructor(obj?: object) {
    super(obj);
  }
}

export const SKIP_EMPLOYEE = 'skipEmployee';

// tslint:disable-next-line:no-namespace
export namespace UsageHistoryStatus {
  export type TypeEnum = 'draft' | 'assigned' | 'confirmed' | 'pending' | 'completed';
  export const TypeEnum = {
    Draft: 'draft' as TypeEnum,
    Assigned: 'assigned' as TypeEnum,
    Confirmed: 'confirmed' as TypeEnum,
    Pending: 'pending' as TypeEnum,
    Completed: 'completed' as TypeEnum,
  };
}
