import { Entity, Field } from '../decorators/decorators';
import { Company } from './company';
import { Model } from './model';

@Entity()
export class SupplierDebt extends Model {
  @Field()
  companyId?: string;

  @Field()
  amount?: number;

  @Field()
  lastTransactionDate?: Date;

  @Field()
  // tslint:disable-next-line:variable-name
  _embeddedCompany?: Company;

  @Field()
  recordedDate?: Date;

  @Field()
  creditAmount?: number;

  @Field()
  debitAmount?: number;

  @Field()
  comment?: string;

  @Field()
  employeeId?: string;

  @Field()
  targetId?: string;
  @Field()
  type?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
