import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class TreatmentAssignedService extends Model {
  @Field()
  serviceName?: string;

  @Field()
  serviceCode?: string;

  @Field()
  quantity?: number;

  @Field()
  orderQuantity?: number;

  @Field()
  comment?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
