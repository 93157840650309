import { ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable, of } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ic-chips-autocomplete-object',
  templateUrl: './chips-autocomplete-object.component.html',
  styleUrls: ['./chips-autocomplete-object.component.scss'],
})
export class ChipsAutocompleteObjectComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, SEMICOLON];
  inputCtrl = new FormControl();
  filteredItems: Observable<Array<object>>;
  filteredItemsBuffer: Array<object>;
  @Input() addOnBlur = false;
  @Input() key: string;
  @Input() title: string;
  @Input() selectOne: boolean;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() selectedItems: Array<object>; // = ['Lemon'];
  @Input() optionItems: Array<object>; // = ['Apple', 'Lemon', 'Lime', 'Orange', 'Strawberry'];
  @Input()
  lazyLoadItemsMethod: (query: string) => Array<object>;
  @ContentChildren(TemplateRef) contentChildrenTemplates: QueryList<TemplateRef<HTMLElement>>;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onAdd = new EventEmitter<object>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onRemove = new EventEmitter<object>();
  @Output() selectedItemsChange = new EventEmitter<Array<object>>();

  @ViewChild('input', { static: false }) inputElement: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  @ViewChild(MatAutocompleteTrigger, { static: false }) matAutocompleteTrigger;

  constructor(public viewContainerRef: ViewContainerRef) {
    this.selectOne = ['true', 'TRUE', true].includes(this.selectOne);
    if (!!this.lazyLoadItemsMethod) {
      this.filteredItems = this.inputCtrl.valueChanges.pipe(
        startWith(''),
        // delay emits
        debounceTime(300),
        // use switch map so as to cancel previous subscribed events, before creating new once
        switchMap((value) => {
          if (!value || typeof value !== 'string') {
            // if no value is pressent, return null
            return of(null);
          } else {
            // lookup from service
            this.filteredItemsBuffer = this.lazyLoadItemsMethod(value);
            return this.filteredItemsBuffer;
          }
        })
      );
    } else {
      this.filteredItems = this.inputCtrl.valueChanges.pipe(
        startWith(null),
        map((item: string | null) => {
          this.filteredItemsBuffer = this._filter(item);
          return this.filteredItemsBuffer;
        })
      );
    }
  }

  ngOnInit(): void {
    this.disabled = !!this.disabled;
    if (!Array.isArray(this.selectedItems)) {
      this.selectedItems = [];
    }
  }

  focus() {
    this.matAutocompleteTrigger._onChange('');
    this.matAutocompleteTrigger.openPanel();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();
    if (this.matAutocomplete.isOpen) {
      if (!!value) {
        const unSelectedItems = this.filteredItemsBuffer;
        if (!!unSelectedItems && unSelectedItems.length > 0) {
          if (this.selectOne) {
            this.selectedItems.forEach((item) => this.remove(item));
          }
          this.selectedItems.push(unSelectedItems[0]);
          this.selectedItemsChange.emit(this.selectedItems);
          this.onAdd.emit(unSelectedItems[0]);
        }
        // Reset the input value
        if (input) {
          input.value = '';
        }
        this.inputCtrl.setValue(null);
      }
    } else {
      this.inputCtrl.setValue('');
    }
  }

  remove(item: object): void {
    const index = this.selectedItems.indexOf(item);
    if (index >= 0) {
      const value = this.selectedItems.splice(index, 1);
      this.selectedItemsChange.emit(this.selectedItems);
      this.filteredItemsBuffer.unshift(value[0]);
      this.onRemove.emit(value[0]);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.dir(event);
    const selectedItem = this.optionItems.find((item) => item[this.key] === event.option.value);
    if (!!selectedItem) {
      if (this.selectOne) {
        this.selectedItems.forEach((item) => this.remove(item));
      }
      this.selectedItems.push(selectedItem);
      this.selectedItemsChange.emit(this.selectedItems);
      this.onAdd.emit(selectedItem);
    }
    this.inputElement.nativeElement.value = '';
    this.inputCtrl.setValue(null);
    this.inputElement.nativeElement.blur();
  }

  private _filter(value: string): Array<object> {
    const unSelectedItems = (this.optionItems || []).filter(
      (item) => item && !this.selectedItems.includes(item)
    );
    if (value) {
      const filterValue = value.toLowerCase();
      return [
        ...unSelectedItems.filter(
          (item) => item[this.title].toLowerCase().indexOf(filterValue) === 0
        ),
        ...unSelectedItems.filter(
          (item) => item[this.title].toLowerCase().indexOf(filterValue) > 0
        ),
      ];
    } else {
      return unSelectedItems;
    }
  }

  findItemTemplate(columnName) {
    let columnTemplate;
    try {
      columnTemplate = this.contentChildrenTemplates.find((template) => {
        return (
          columnName ===
          JSON.parse(template.elementRef.nativeElement.data.substring('bindings='.length))[
            `ng-reflect-ic-for`
          ]
        );
      });
    } catch (err) {
      columnTemplate = undefined;
    }
    if (!!columnTemplate) {
      return columnTemplate;
    } else {
      const field = '_view';
      throw new Error(`
            You must define a template for "${columnName}" column of ${this.constructor.name}
            in ${this.viewContainerRef[field].context.constructor.name}
            As:
                <ng-template let-item icFor=${columnName}">
                .....
                </ng-template>
      `);
    }
  }
}
