import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class PricingHistory extends Model {
  @Field()
  price?: number;

  @Field()
  effectiveDate?: Date;

  @Field()
  expirationDate?: Date;

  @Field()
  compensationAmount?: number;

  constructor(obj?: object) {
    super(obj);
  }
}
