import { Observable } from 'rxjs';
import { AbstractApi, AbstractDao, AbstractRepository, IcConfig } from '..';
import { Model } from '../models/model';

// tslint:disable-next-line:no-shadowed-variable
export class AbstractDeepRepository<
  T extends Model
  /*, R extends AbstractDao<T> | AbstractDao<T>, A extends AbstractApi<T> | AbstractApi<T>*/
>
  // @ts-ignore
  extends AbstractRepository {
  protected dao: AbstractDao<T>;
  protected api: AbstractApi<T>;
  protected icConfig: IcConfig;

  protected constructor(clazzT: new (obj: object) => T) {
    super(clazzT);
  }

  public async create(t: T, deepPath: string): Promise<T> {
    // let customerID: CustomerID;
    try {
      if (['online', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.api.create(t, deepPath);
      }
      if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
        await this.dao.create(t);
      }
    } catch (err) {
      throw err;
    }
    return t;
  }

  public async update(t: T, deepPath: string) {
    if (['online', 'sync'].includes(this.icConfig.dataMode)) {
      await this.api.update(t, deepPath);
    }
    if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
      await this.dao.update(t);
    }
  }

  public async replace(t: T, deepPath: string) {
    if (['online', 'sync'].includes(this.icConfig.dataMode)) {
      await this.api.replace(t, deepPath);
    }
    if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
      await this.dao.replace(t);
    }
  }

  public async delete(id, deepPath: string) {
    if (['online', 'sync'].includes(this.icConfig.dataMode)) {
      await this.api.delete(id, deepPath);
    }
    if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
      await this.dao.delete(id);
    }
  }

  public search(query: any, deepPath?: string): Observable<T[]> {
    // @ts-ignore
    const observable = new Observable<T[]>(async (observer) => {
      let result: T[];
      if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
        result = await this.dao.search({ ...query });
        observer.next(result);
      }
      if (['online', 'sync'].includes(this.icConfig.dataMode)) {
        result = await this.api.search({ ...query }, deepPath);
        observer.next(result);
      }
      observer.complete();
    });
    return observable;
  }

  public getQuery(query: any, id: string, deepPath: string): Observable<T> {
    // @ts-ignore
    const observable = new Observable<T>(async (observer) => {
      let t: T;
      if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.dao.get(id);
        observer.next(t);
      }
      if (['online', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.api.getQuery(query, id, deepPath);
        observer.next(t);
      }
      observer.complete();
    });
    return observable;
  }

  public get(id: string, deepPath: string): Observable<T> {
    // @ts-ignore
    const observable = new Observable<T>(async (observer) => {
      let t: T;
      if (['offline', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.dao.get(id);
        observer.next(t);
      }
      if (['online', 'sync'].includes(this.icConfig.dataMode)) {
        t = await this.api.get(id, deepPath);
        observer.next(t);
      }
      observer.complete();
    });
    return observable;
  }
}
