/**
 * DTClinic APIs
 * This is an API spec of Service Collections and Instances exposed by Dan Thanh Clinic.
 *
 * OpenAPI spec version: 1.0.0
 * Contact: dainguyen4dtclinic@gmail.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class Phone extends Model {
  @Field()
  type?: Phone.TypeEnum;
  @Field()
  number?: string;
  @Field()
  primary?: boolean;

  constructor(obj?: object) {
    super(obj);
  }
}

// tslint:disable-next-line:no-namespace
export namespace Phone {
  export type TypeEnum = 'home' | 'mobile';
  export const TypeEnum = {
    Home: 'home' as TypeEnum,
    Mobile: 'mobile' as TypeEnum,
  };
}
