import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class ExternalProduct extends Model {
  @Field()
  productName?: string;

  @Field()
  productType?: string;

  @Field()
  description?: string;

  @Field()
  unit?: string;

  constructor(obj?: object) {
    super(obj);
  }
}
