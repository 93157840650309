import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { PageEvent } from '@angular/material/paginator/typings/paginator';
import { MatSort } from '@angular/material/sort';
import { Sort } from '@angular/material/sort/typings/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Model } from '../../models/models';
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'ic-data-table[displayedProperties]',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnInit, AfterContentInit, OnChanges {
  @Input()
  title: string;

  @Input()
  tableId: string;

  @Input()
  headerActions: TemplateRef<HTMLElement>;

  @Input()
  displayedProperties: Array<{
    key: string;
    sortable: boolean;
    sortActive?: boolean;
    sortActiveDirection?: 'asc' | 'desc' | 'ASC' | 'DESC';
    title: string;
    type: string;
    parse?: (obj: object) => any;
    clickAction?: (obj: object) => any;
    template?: TemplateRef<HTMLElement>;
  }>;

  @Input()
  pageSizeOptions: Array<number>;

  @Input()
  lazyLoadDataMethod: ({ topId, limit, pageIndex, sortBy, sortOrder }) => Array<Model>;

  @Input()
  clickRow: (model: Model) => void;

  @Input()
  eagerData: Array<Model>;
  //
  // @Input()
  // actionsCell: TemplateRef<HTMLElement>;
  @ContentChildren(TemplateRef) columnTemplates: QueryList<TemplateRef<HTMLElement>>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  get displayedColumns() {
    const columns = [...this.displayedProperties.filter((property) => !!property.key)];
    // if (!!this.actionsCell) {
    //   columns.push({key: 'actions', type: 'actions', title: 'Actions'});
    // }
    return columns;
  }

  get columnsOrder() {
    return this.displayedColumns.map((property) => property.key);
  }

  get dataTableId() {
    return this.tableId;
  }

  public dataSource: MatTableDataSource<Model> | Array<Model>;
  public paginatorIndex: number;
  public paginatorLimit: number;
  public resultsLength: number;
  indexIds: Array<string> = [];
  public topIndexId = '';

  public sortActive;
  public sortActiveDirection;

  constructor(
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef,
    public translateService: TranslateService
  ) {
    translateService.onLangChange.subscribe(async () => {
      this.paginator._intl.itemsPerPageLabel = await this.translateService
        .get('Paginator.Items.Per.Page')
        .toPromise();
    });
  }

  async ngAfterContentInit() {
    this.pageSizeOptions =
      this.pageSizeOptions || (!!this.lazyLoadDataMethod ? [5, 10, 20] : undefined);
    if (this.pageSizeOptions) {
      this.paginatorIndex = 0;
      this.paginatorLimit = this.pageSizeOptions[0];
    }
    await this.refreshData();
    this.paginator._intl.itemsPerPageLabel = await this.translateService
      .get('Paginator.Items.Per.Page')
      .toPromise();
  }

  ngOnInit(): void {
    const sortActiveProperty = this.displayedProperties.find((property) => property.sortActive);
    if (sortActiveProperty) {
      this.sortActive = sortActiveProperty.key;
      if (['asc', 'desc', 'ASC', 'DESC'].includes(this.sortActiveDirection)) {
        this.sortActiveDirection = sortActiveProperty.sortActiveDirection.toLocaleLowerCase();
      } else {
        this.sortActiveDirection = 'asc';
      }
    }
  }

  // https://blog.angular-university.io/how-does-angular-2-change-detection-really-work/
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.eagerData &&
      !changes.eagerData.isFirstChange() &&
      changes.eagerData.currentValue !== changes.eagerData.previousValue
    ) {
      this.initEagerData();
    }
  }

  private getDeepProperty(obj: object, value: string | ((obj: object) => any)) {
    if (typeof value === 'function') {
      return value(obj);
    } else {
      return UtilityService.getDeeplyProperty(obj, value as string);
    }
  }

  clickRowAction(row: any, column: any) {
    if (typeof this.clickRow === 'function' && column.type !== 'template') {
      return this.clickRow(row);
    }
  }

  clickItem(event: MouseEvent, row: any, clickAction: (obj: object) => any) {
    event.stopPropagation();
    if (typeof clickAction === 'function') {
      return clickAction(row);
    }
  }

  // clickItem(row: any, clickAction: (obj: object) => any) {
  //   this.

  // }

  findColumnTemplate(columnName: string) {
    let columnTemplate;
    try {
      columnTemplate = this.columnTemplates.find((template) => {
        return (
          columnName ===
          JSON.parse(template.elementRef.nativeElement.data.substring('bindings='.length))[
            'ng-reflect-ic-column'
          ]
        );
      });
    } catch (err) {
      columnTemplate = undefined;
    }
    if (!!columnTemplate) {
      return columnTemplate;
    } else {
      const field = '_view';
      throw new Error(`
            You must define a template for "${columnName}" column of ${this.constructor.name}
            in ${this.viewContainerRef[field].context.constructor.name}
            As:
                <ng-template let-item icColumn=${columnName}">
                .....
                </ng-template>
      `);
    }
  }

  public async refreshData() {
    if (!!this.lazyLoadDataMethod) {
      await this.initLazyData();
    } else {
      this.initEagerData();
    }
  }

  private async initLazyData() {
    this.resultsLength = 999999; // enable next button
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe((sort: Sort) => {
      this.sortActive = sort.active;
      this.sortActiveDirection = sort.direction;
      this.paginatorIndex = this.paginator.pageIndex = 0;
      const index = this.paginatorIndex * this.paginatorLimit;
      this.topIndexId = index > 0 && this.indexIds.length >= index ? this.indexIds[index - 1] : '';
      this.loadCurrentLazyData();
    });
    this.paginator.page.subscribe(async (pageEvent: PageEvent) => {
      this.paginatorIndex = pageEvent.pageIndex;
      this.paginatorLimit = pageEvent.pageSize;
      //
      const index = this.paginatorIndex * this.paginatorLimit;
      this.topIndexId = index > 0 && this.indexIds.length >= index ? this.indexIds[index - 1] : '';
      //
      await this.loadCurrentLazyData();
    });
    await this.loadCurrentLazyData();
  }

  private async loadCurrentLazyData() {
    if (!this.lazyLoadDataMethod) {
      return;
    }
    const index = this.paginatorIndex * this.paginatorLimit;
    const options = {
      topId: this.topIndexId,
      limit: this.paginatorLimit,
      pageIndex: +this.paginatorIndex + 1,
      sortBy: this.sortActive,
      sortOrder: this.sortActiveDirection,
    };
    this.dataSource = await this.lazyLoadDataMethod(options);
    this.indexIds = this.indexIds.filter((indexId, i) => i < index);
    this.indexIds.push(...this.dataSource.map((item) => item.id));
    if (this.dataSource.length < this.paginatorLimit) {
      // disable next button
      this.resultsLength = this.indexIds.length;
    }
  }

  public addDataItem(item: Model) {
    this.dataSource = [item, ...this.dataSource];
    // this.indexIds = [item.id, ...this.indexIds];
    // this.resultsLength++;
  }

  public deleteDataItem(item: Model) {
    this.dataSource = (this.dataSource as Array<Model>).filter(
      (indexItem) => indexItem.id !== item.id
    );
    this.indexIds = this.indexIds.filter((indexId) => indexId !== item.id);
    // this.resultsLength--;
  }

  private initEagerData() {
    const dataItems = [...(this.eagerData || [])];
    this.dataSource = new MatTableDataSource(dataItems);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (obj, key) => {
      const displayedProperty = this.displayedProperties.find((property) => property.key === key);
      return this.getDeepProperty(obj, displayedProperty.parse || displayedProperty.key);
    };
    this.dataSource.sort = this.sort;
  }

  sortable(column) {
    if (['avatar', 'image', 'template'].includes(column.type)) {
      return !!column.sortable;
    } else {
      return [null, undefined, true].includes(column.sortable);
    }
  }
}
