import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'ic-input-dialog',
  templateUrl: './input-dialog.html',
  styleUrls: ['./input-dialog.scss'],
})
// tslint:disable-next-line:component-class-suffix
export class InputDialog implements OnInit {
  title: string;
  message: string;
  inputValue = '';
  required: boolean;

  constructor(
    public dialogRef: MatDialogRef<InputDialog>,
    @Inject(MAT_DIALOG_DATA) public data: object
  ) {
    this.dialogRef.disableClose = true;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    // @ts-ignore
    this.message = this.data.message || '';
    // @ts-ignore
    this.title = this.data.title || '';
    // @ts-ignore
    this.required = !!this.data.required;
  }

  onOKClick() {
    this.dialogRef.close(this.inputValue);
  }
}
