import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  Address,
  Autowired,
  Contact,
  Customer,
  CustomerProfile,
  Employee,
  Phone,
  Service,
  User,
  UtilityService,
} from '../../ic-core';
import { CustomerProfileRepository } from '../repositories/customer-profile.repository';
import { CustomerRepository } from '../repositories/customer.repository';
import { EmployeeRepository } from '../repositories/employee.repository';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  private storedSessionEmployee: Employee[];

  @Autowired()
  protected utilityService: UtilityService;
  @Autowired()
  protected customerRepository: CustomerRepository;
  @Autowired()
  protected customerProfileRepository: CustomerProfileRepository;

  @Autowired()
  protected employeeRepository: EmployeeRepository;

  async createCustomer(customer: Customer): Promise<Customer> {
    try {
      customer = await this.customerRepository.create(customer);
    } catch (err) {
      throw err;
    }
    return customer;
  }

  async updateCustomer(dataChanged: Customer): Promise<void> {
    await this.customerRepository.update(dataChanged);
  }

  async deleteCustomer(id): Promise<void> {
    await this.customerRepository.delete(id);
  }

  searchCustomers(query: any): Observable<Customer[]> {
    return this.customerRepository.search(query);
  }

  async getCustomer(customerId: string): Promise<Customer> {
    return await this.customerRepository.get(customerId).toPromise();
  }

  async getCustomerProfile(customerId: string): Promise<CustomerProfile> {
    const customerProfiles: CustomerProfile[] = await this.customerProfileRepository
      .search({ customerId }, `customers/${customerId}`)
      .toPromise();
    let customerProfile: CustomerProfile;
    if (Array.isArray(customerProfiles) && customerProfiles.length > 0) {
      customerProfile = customerProfiles[0];
    } else {
      customerProfile = new CustomerProfile({ customerId }); // init empty obj
    }
    this.initEmptyValueForCustomerProfile(customerProfile);
    return customerProfile;
  }

  initEmptyValueForCustomerProfile(customerProfile: CustomerProfile): void {
    customerProfile.personalAnamnesis = customerProfile.personalAnamnesis || [];
    customerProfile.familyAnamnesis = customerProfile.familyAnamnesis || [];
    customerProfile.interests = customerProfile.interests || [];
    customerProfile.habits = customerProfile.habits || [];
    customerProfile.notes = customerProfile.notes || [];
    customerProfile.medicalHistories = customerProfile.medicalHistories || [];
    customerProfile.medicalHistories.push({});
    customerProfile.notes = customerProfile.notes || [];
    customerProfile.notes.push({});
    customerProfile.shared = customerProfile.shared || [];
  }

  async createOrUpdateCustomerProfile(
    customerId: string,
    customerProfileId: string,
    updateProperties: CustomerProfile
  ): Promise<void> {
    updateProperties.customerId = customerId;
    if (Array.isArray(updateProperties.medicalHistories)) {
      updateProperties.medicalHistories = updateProperties.medicalHistories.filter(
        (medicalHistory) => {
          return (
            Object.entries(medicalHistory).filter(
              ([key, value]) => key !== 'id' && !['', null, undefined].includes(value)
            ).length > 0
          );
        }
      );
    }

    if (Array.isArray(updateProperties.notes)) {
      updateProperties.notes = updateProperties.notes.filter((note) => {
        return (
          Object.entries(note).filter(
            ([key, value]) => key !== 'id' && !['', null, undefined].includes(value)
          ).length > 0
        );
      });
    }

    if (!!customerProfileId) {
      updateProperties.id = customerProfileId;
      await this.customerProfileRepository.update(updateProperties, `customers/${customerId}`);
    } else {
      await this.customerProfileRepository.create(updateProperties, `customers/${customerId}`);
    }
  }

  async updateCustomerProfile(
    customerId: string,
    customerProfileId: string,
    updateProperties: CustomerProfile
  ): Promise<void> {
    updateProperties.id = customerProfileId;
    await this.customerProfileRepository.update(updateProperties, `customers/${customerId}`);
  }

  async createEmployee(employee: Employee): Promise<Employee> {
    try {
      employee = await this.employeeRepository.create(employee);
    } catch (err) {
      throw err;
    }
    return employee;
  }

  async updateEmployee(dataChanged: Employee): Promise<void> {
    try {
      await this.employeeRepository.update(dataChanged);
    } catch (err) {
      throw err;
    }
  }

  async deleteEmployee(id): Promise<void> {
    await this.employeeRepository.delete(id);
  }

  searchEmployees(query: any): Observable<Employee[]> {
    const queryAll: boolean = Object.keys(query).length === 0;
    if (queryAll && this.storedSessionEmployee) {
      return of(this.storedSessionEmployee);
    }
    return this.employeeRepository.search(query).pipe(
      tap((employees) => {
        if (queryAll) {
          this.storedSessionEmployee = employees;
        }
      })
    );
  }

  async getEmployee(employeeId: string): Promise<Employee> {
    return await this.employeeRepository.get(employeeId).toPromise();
  }

  generateEmptyUser(): User {
    return new User({
      fullName: '',
      birthday: null,
      gender: null,
      photo: 'assets/images/xs/avatar.jpg',
      contact: new Contact({
        email: '',
        addresses: [new Address({ city: '', district: '', street: '' })],
        phones: [new Phone({ number: '', primary: true, type: Phone.TypeEnum.Mobile })],
      }),
    });
  }

  generateEmptyEmployee(): Employee {
    return this.generateEmptyUser() as Employee;
  }

  generateEmptyCustomer(): Customer {
    return this.generateEmptyUser() as Customer;
  }

  resetStoredSessionEmployee(): void {
    this.storedSessionEmployee = undefined;
  }
}
