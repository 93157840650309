import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';

@Entity()
export class WorkShift extends Model {
  @Field()
  title?: string;
  @Field()
  startHour?: number;
  @Field()
  endHour?: number;
  @Field()
  date?: Date;
  @Field()
  employeeType: string;

  constructor(obj?: object) {
    super(obj);
  }
}
