import { Injectable } from '@angular/core';
import { AbstractRepository, Employee } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class EmployeeRepository extends AbstractRepository<Employee> {
  constructor() {
    super(Employee);
  }

  async getAvailableEmployeeIds(body: { startTime: Date; endTime: Date; customerId: string }) {
    const response: {
      'default-technicianId': string;
      'default-doctorId': string;
      'available-technician-list': Array<string>;
      'available-doctor-list': Array<string>;
    } = await this.api.request('POST', this.api.url + '/employee_picker', { body });

    let result = [
      response['default-doctorId'],
      response['default-technicianId'],
      ...response['available-doctor-list'],
      ...response['available-technician-list'],
    ];
    result = result.filter((item, index, source) => source.indexOf(item) === index);
    return result;
  }
}
