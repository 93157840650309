import { Entity, Field } from '../decorators/decorators';
import { Attachment } from './attachment';
import { Model } from './model';
import { Prescription } from './prescription';
import { SharedUser } from './shared-user';
import { ShortEmployee } from './shortEmployee';

export enum TreatmentHistoryDisplayType {
  SERVICE = 'SERVICE',
  MEDICINE = 'MEDICINE',
  TECHNICIAN = 'TECHNICIAN',
}

@Entity()
export class Treatment extends Model {
  @Field()
  customerId?: string;

  @Field()
  doctorId?: string;

  @Field()
  diagnosis?: string;

  @Field()
  prescription?: Prescription;

  @Field({ itemType: Attachment })
  attachments?: Array<Attachment>;

  @Field({ itemType: SharedUser })
  shared?: Array<SharedUser>;

  @Field()
  comment?: string;

  @Field()
  internalComment?: string;

  @Field()
  externalComment?: string;

  @Field()
  orderId?: string;

  @Field()
  nextAppointmentDate?: Date;

  @Field({ itemType: ShortEmployee })
  assignedTechnicians?: Array<ShortEmployee>;

  @Field()
  branch?: string;

  @Field()
  // tslint:disable-next-line:variable-name
  _embeddedEmployee?: ShortEmployee;

  constructor(obj?: object) {
    super(obj);
  }
}
