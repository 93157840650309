import { Injectable } from '@angular/core';
import { AbstractRepository, Branch } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class BranchRepository extends AbstractRepository<Branch> {
  constructor() {
    super(Branch);
  }
}
