import {
  AfterContentInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ElementFinder } from 'protractor';

@Component({
  selector: 'ic-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.scss'],
})
export class DialogContainerComponent implements OnInit {
  public mobileDivide = false;
  @Input() headerTitle: string;
  @Input() headerActionTemplate: TemplateRef<any>;
  @Input() bodyTemplate: TemplateRef<any>;
  isInit = false;
  hasHeader = true;

  @ViewChild('title', { static: false, read: ElementRef }) title: ElementRef;
  @ViewChild('execution', { static: false, read: ElementRef }) execution: ElementRef;

  constructor() {}

  ngOnInit() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      this.mobileDivide = true;
    }
    setTimeout(() => {
      this.hasHeader =
        !!this.headerTitle ||
        this.title.nativeElement.innerText.trim() !== '' ||
        this.execution.nativeElement.innerText.trim();
      this.isInit = true;
    });
  }
}
