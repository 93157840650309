import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

declare const Croppie: any;

const blobToBase64 = (blob) =>
  new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

@Component({
  selector: 'ic-crop-photo',
  templateUrl: './crop-photo.component.html',
  styleUrls: ['./crop-photo.component.scss'],
})
export class CropPhotoComponent implements AfterViewInit, OnChanges {
  @ViewChild('cropZone', { static: false }) cropZone: ElementRef;
  @Input() dataUrl: string;
  @Input() viewport: number;
  @Input() boundary: number;

  @Output() cropped = new EventEmitter<{
    file: File | Blob;
    dataUrl: string;
    base64String: string | ArrayBuffer;
  }>();
  croppie: any;

  constructor() {}

  ngAfterViewInit() {
    setTimeout(() => {
      const element = this.cropZone.nativeElement;
      this.viewport = this.viewport || 300;
      this.boundary = this.boundary || 400;
      this.croppie = new Croppie(element, {
        viewport: { width: this.viewport, height: this.viewport, type: 'square' }, // Valid type values:'square' 'circle'
        boundary: { width: this.boundary, height: this.boundary },
        showZoomer: false,
      });
      this.croppie.bind({
        url: this.dataUrl, // 'https://foliotek.github.io/Croppie/demo/cat.jpg',
        orientation: 4,
      });
    }, 1000);
  }

  ngOnChanges(changes: SimpleChanges): void {}

  async crop() {
    this.croppie.result('blob').then(async (file) => {
      const dataUrl = URL.createObjectURL(file);
      const base64String = await blobToBase64(file);
      this.cropped.emit({ file, dataUrl, base64String });
    });
  }
}
