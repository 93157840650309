import { Entity, Field } from '../decorators/decorators';
import { Branch } from './branch';
import { Model } from './model';
import { Product } from './product';

@Entity()
export class Inventory extends Model {
  @Field()
  productId?: string;

  @Field()
  // tslint:disable-next-line:variable-name
  _embeddedProduct?: EmbeddedProduct;

  @Field()
  // tslint:disable-next-line:variable-name
  _embeddedBranch?: EmbeddedBranch;

  @Field()
  transactionDate?: Date;

  @Field()
  lastTransactionDate?: Date;

  @Field()
  amount?: number;

  @Field()
  creditAmount?: number;

  @Field()
  debitAmount?: number;

  @Field()
  inventoryLocation?: string;

  @Field()
  toLocation?: string;

  @Field()
  notes?: string;

  constructor(obj?: object) {
    super(obj);
  }
}

export interface EmbeddedProduct extends Product {
  productCode: string;
  unit: string;
}
export interface EmbeddedBranch extends Branch {
  branchName: string;
}
