import { Entity, Field } from '../decorators/decorators';
import { Address } from './address';
import { Model } from './model';

@Entity()
export class Storage extends Model {
  @Field()
  storageName?: string;

  @Field()
  comment?: string;

  @Field({ mapping: './' })
  address: Address;

  constructor(obj?: object) {
    super(obj);
  }
}
