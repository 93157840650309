import { Injectable } from '@angular/core';
import { AbstractApi, Employee } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class EmployeeApi extends AbstractApi<Employee> {
  protected uri: string;
  constructor() {
    super(Employee);
  }
}
