import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ic-widget-header-execution',
  templateUrl: './widget-header-execution.component.html',
  styleUrls: ['./widget-header-execution.component.scss'],
})
export class WidgetHeaderExecutionComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
