import { Entity, Field } from '../decorators/decorators';
import { Model, Status } from './model';
import { ShortEmployee } from './shortEmployee';

@Entity()
export class Absence extends Model {
  @Field()
  reason?: string;
  @Field()
  employeeId?: string;
  @Field()
  leaveDate?: Date;
  @Field()
  startingHour?: { hours: number; minutes: number };
  @Field()
  endingHour?: { hours: number; minutes: number };
  @Field()
  absenceStatus?: Status.TypeEnum;
  @Field()
  notes?: string;
  @Field()
  leaveOption?: string;
  @Field()
  acknowledge?: boolean;
  @Field()
  startHour: number;
  @Field()
  startMinute: number;
  @Field()
  endHour: number;
  @Field()
  endMinute: number;
  @Field()
  // tslint:disable-next-line:variable-name
  _embeddedEmployee?: ShortEmployee;

  constructor(obj?: object) {
    super(obj);
  }

  fromRequestData(data: object): void {
    super.fromRequestData(data);

    this.startingHour = { hours: this.startHour, minutes: this.startMinute };
    this.endingHour = { hours: this.endHour, minutes: this.endMinute };
  }

  toRequestData(): object {
    if (this.startingHour) {
      this.startHour = this.startingHour.hours;
      this.startMinute = this.startingHour.minutes;
    }

    if (this.endingHour) {
      this.endHour = this.endingHour.hours;
      this.endMinute = this.endingHour.minutes;
    }
    this.startingHour = undefined;
    this.endingHour = undefined;
    return super.toRequestData();
  }
}
