import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'ic-widget-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss'],
})
export class WidgetHeaderComponent implements OnInit {
  @ViewChild('controlFullScreen', { static: false }) controlFullScreen: ElementRef;

  public allowFullScreen = true;
  public isFullScreen = false;

  public onExitFullScreen;
  public onExpandFullScreen;

  ngOnInit(): void {}

  exitFullScreen() {
    this.isFullScreen = false;
    if (this.onExitFullScreen) {
      this.onExitFullScreen();
    }
  }

  expandFullScreen() {
    this.isFullScreen = true;
    if (this.onExpandFullScreen) {
      this.onExpandFullScreen();
    }
  }

  setAllowFullScreen(allowFullScreen: boolean) {
    this.allowFullScreen = allowFullScreen;
    if (this.controlFullScreen) {
      if (allowFullScreen) {
        this.controlFullScreen.nativeElement.classList.remove('hidden');
      } else {
        this.controlFullScreen.nativeElement.classList.add('hidden');
      }
    }
  }
}
