import { Entity, Field } from '../decorators/decorators';
import { Model } from './model';
import { PackageItem } from './package-item';

@Entity()
export class Package extends Model {
  @Field()
  packageCode?: string;

  @Field()
  packageName?: string;

  @Field()
  description?: string;

  @Field({ itemType: PackageItem })
  packageItems?: Array<PackageItem>;

  @Field()
  packagePrice?: number;

  @Field()
  quantity?: number;

  constructor(obj?: object) {
    super(obj);
  }
}
