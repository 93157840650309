import { Injectable, Injector } from '@angular/core';
import 'reflect-metadata';

const DESIGN_META_DATA = {
  APP: 'design:meta:data:key:app',
  CONFIG: 'design:meta:data:key:config',
  POST_INIT: 'design:meta:data:key:post.init',
  AUTOWIRED: 'design:meta:data:key:autowired',
  SERVICE: 'design:meta:data:key:service',
  SERVICE_MAPPING: 'design:meta:data:key:service:mapping',
  PATH: 'design:meta:data:key:path',
  METHOD: 'design:meta:data:key:method',
  PARAMETER: 'design:meta:data:key:parameter',
  PATH_PARAMETER: 'design:meta:data:key:path:parameter',
  REQUEST: 'design:meta:data:key:request',
  RESPONSE: 'design:meta:data:key:response',
  QUERY: 'design:meta:data:key:query',
  QUERY_PARAMETER: 'design:meta:data:key:query:parameter',
  BODY: 'design:meta:data:key:body',
  BODY_PARAMETER: 'design:meta:data:key:body:parameter',
  HEADERS: 'design:meta:data:key:headers',
  HEADERS_PARAMETER: 'design:meta:data:key:headers:parameter',
  ENTITY: 'design:meta:data:key:entity',
};

// https://stackoverflow.com/questions/39409328/storing-injector-instance-for-use-in-components
@Injectable({
  providedIn: 'root',
})
export class IcInjectorService {
  private static injector: Injector;

  public static setAppInjector(injector: Injector) {
    if (this.injector) {
      console.error('Programming error: AppInjector was already set');
    } else {
      this.injector = injector;
    }
  }

  public static getInstance(clazz: () => void) {
    return this.injector.get(clazz);
  }
}
