import { Injectable } from '@angular/core';
import { AbstractApi, Treatment } from '../../ic-core/';

@Injectable({
  providedIn: 'root',
})
export class TreatmentApi extends AbstractApi<Treatment> {
  constructor() {
    super(Treatment);
  }
}
