import { Injectable } from '@angular/core';
import { AbstractRepository, Customer } from '../../ic-core';

@Injectable({
  providedIn: 'root',
})
export class CustomerRepository extends AbstractRepository<Customer> {
  constructor() {
    super(Customer);
  }
}
